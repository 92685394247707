%standard-interior-link {
  transition: .25s color ease-in-out;
  line-height: (19 / 14);
  color: #767f84;
  font-size: em(14);
  font-weight: 400;
  &:hover {
    color: $teal;
  }
}

.site-header {
  z-index: 4;
  padding: 0;
  width: 100%;
  transform: translateZ(0);
  @media (max-width: 991px) {
    position: fixed;
    top: 0 !important;
    left: 0;
    transition: .25s left linear;
  }
  @media (min-width: 992px) {
    position: absolute;
  }
  @media (min-width: 992px) and (max-width: 1200px) {
    font-size: 14px;
  }
  &--fixed {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
  }
  > .inner {
    @extend %constrain--full;
    @extend %constrain;
    display: flex;
    flex-direction: column;
  }
  a {
    display: block;
    &:hover {
      text-decoration: none;
    }
  }
  ul {
    margin: 0;
    padding: 0;
    list-style: none;
  }
  @media (min-width: 992px) {
    padding: 0;
  }
  &__sub,
  &__main {
    display: flex;
    width: 100%;
  }
  &__sub {
    background-color: white;
    @media (max-width: 991px) {
      display: none;
    }
    > span {
      display: flex;
      padding: em(8) em(28);
      width: 100%;
      align-items: center;
      justify-content: flex-end;
    }
  }
  &__expert,
  &__sub-menu {
    display: flex;
    font-family: $proxima;
    align-items: center;
  }
  &__expert {
    padding-right: em(30);
    span {
      margin-right: em(10);
      color: #4a4a4a;
    }
    a {
      transition: .25s color ease-in-out;
      color: $orange;
      &:hover {
        color: darken($orange, 10%);
      }
    }
  }
  &__sub-menu {
    position: relative;
    &:before {
      display: block;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      background-color: $gray-light;
      width: 1px;
      height: 100%;
      content: '';
    }
    li {
      display: flex;
      margin-bottom: 0;
      margin-left: em(30);
      align-items: center;
      &:first-child {
        svg {
          margin-top: -3px;
          width: em(9);
          height: em(13);
        }
      }
      &:nth-child(2) {
        svg {
          margin-top: -2px;
          width: em(15);
          height: em(13);
        }
      }
      &:last-child {
        svg {
          width: em(15);
          height: em(12);
        }
      }
      svg {
        margin-right: em(4);
        path {
          transition: .25s fill ease-in-out;
          fill: #4a4a4a;
        }
      }
      a {
        transition: .25s color ease-in-out;
        color: #4a4a4a;
        &:hover {
          color: $teal;
          svg path {
            fill: $teal;
          }
        }
      }
    }
  }
  &__main {
    @media (min-width: 768px) {
      border-top: 1px solid #eaebec;
    }
  }
  &__logo {
    background-color: $brown;
    &-link {
      @media (max-width: 767px) {
        padding: 17.5px 7px;
      }
      @media (min-width: 768px) and (max-width: 991px) {
        padding: 13.5px 14px;
      }
      @media (min-width: 992px) {
        padding: em(23) em(17);
      }
    }
    img {
      @media (max-width: 767px) {
        width: 163px;
        height: 14px;
      }
      @media (min-width: 768px) and (max-width: 991px) {
        width: 250px;
        height: 22px;
      }
      @media (min-width: 992px) {
        width: em(334);
        height: em(29);
      }
    }
  }
  &__main-menu {
    background-color: white;
    flex-grow: 1;
    > div,
    > div > ul {
      width: 100%;
    }
    > div > ul {
      display: flex;
      align-items: stretch;
      > li {
        margin-bottom: 0;
        @media (min-width: 992px) {
          flex-grow: 1;
        }
        > a {
          white-space: nowrap;
        }
        &.hs-item-has-children {
          > a {
            span {
              position: relative;
              &:after {
                display: block;
                position: absolute;
                left: 0;
                top: 100%;
                transform: translateY(50%);
                margin: auto;
                background-image: url('//cdn2.hubspot.net/hubfs/1608284/assets/images/dropdown.svg');
                background-repeat: no-repeat;
                background-size: contain;
                width: em(12, 20);
                height: em(7, 20);
                content: '';
              }
            }
          }
        }
        &:first-child {
          margin-left: 3px;
          > .hs-menu-children-wrapper {
            columns: em(180) 3 !important;
          }
        }
        &:first-child,
        &:nth-child(2),
        &:nth-child(3) {
          > .hs-menu-children-wrapper {
            padding: em(42);
            columns: em(180) 2;
            .hs-menu-depth-2 {
              display: flex;
              margin-bottom: 0;
              vertical-align: top;
              break-inside: avoid;
              flex-direction: column;
              > a {
                @extend %partial-underline;
                transition: .25s color ease-in-out;
                margin-bottom: em(13, 14);
                line-height: (17 / 18);
                letter-spacing: em(.5, 14);
                color: $gray-dark;
                font-size: em(14);
                font-weight: 600;
                &:hover {
                  color: $teal;
                }
              }
              > ul {
                display: block;
              }
            }
            .hs-menu-depth-3 {
              margin-bottom: em(12);
              > a {
                @extend %standard-interior-link;
              }
            }
            .hs-menu-children-wrapper {
              position: relative;
            }
          }
        }
        &:not(:last-child) {
          margin-right: 3px;
          @media (max-width: 991px) {
            display: none;
          }
          &:hover {
            > a {
              background-color: white;
            }
          }
          .hs-menu-depth-2 {
            margin-bottom: em(12);
            a {
              @extend %standard-interior-link;
            }
          }
          > a {
            transition: .25s background-color ease-in-out;
            background-color: $gray-lightest;
            padding: em(25.5, 20) em(12, 20);
            line-height: (24 / 20);
            color: $gray-dark;
            font-size: em(20);
            font-weight: 700;
          }
        }
        &:last-child {
          @media (max-width: 991px) {
            margin-left: 3px;
          }
          > a {
            transition: .5s background-color ease-in-out;
            background-color: $orange;
            line-height: (27 / 22);
            color: white;
            font-weight: 700;
            @media (max-width: 767px) {
              padding: 18px 11px;
              font-size: 12px;
            }
            @media (min-width: 768px) and (max-width: 991px) {
              padding: 15px 33px;
              font-size: 16px;
            }
            @media (min-width: 992px) {
              font-size: em(22);
              padding: em(24.5, 22) em(12, 22);
            }
            &:hover {
              background-color: darken($orange, 5%);
            }
          }
        }
      }
    }
    a {
      font-family: $proxima;
    }
    .hs-menu-depth-1 {
      &:not(:nth-child(3)) {
        &:hover {
          > .hs-menu-children-wrapper {
            background-color: rgba(255, 255, 255, .98);
            transform: translateY(0%);
            opacity: 1;
          }
        }
        > .hs-menu-children-wrapper {
          display: block;
          transform: translateY(-100%);
          padding: em(42);
          transition: .25s all ease-in-out;
          z-index: -1;
          opacity: 0;
        }
      }
      &:nth-child(3) {
        &:hover {
          > .hs-menu-children-wrapper {
            background-color: rgba(255, 255, 255, .98);
            transform: translateY(0%) translateX(calc(-100% + 252px));
            opacity: 1;
          }
        }
        > .hs-menu-children-wrapper {
          display: block;
          min-width: 460px;
          transform: translateY(-100%) translateX(calc(-100% + 252px));
          padding: em(42);
          transition: .25s all ease-in-out;
          z-index: -1;
          opacity: 0;
        }
      }
    }
    .hs-menu-children-wrapper {
      position: absolute;
      text-align: left;
    }
  }
  &__secondary-menu {
    .hs-menu-depth-1 {
      > a {
        padding: .5em;
      }
    }
    @media (max-width: 479px) {
      display: none;
    }
  }
}
