.arrow,
%arrow {
  display: block;
  width: 100%;
  height: 18px;
  min-height: 18px;
  position: relative;
  &:before,
  &:after {
    content: '';
    display: inline-block;
    position: absolute;
  }
  &:before {
    // line
    width: 0%;
    height: 2px;
    background-color: $gray-dark;
    top: 50%;
    transform: translateY(-50%);
    left: 0;
    transition: .5s width ease-in-out;
  }
  &:after {
    // arrow
    width: 11px;
    height: 18px;
    background-image: url('//cdn2.hubspot.net/hubfs/1608284/assets/images/hover-arrow.svg');
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center center;
    left: 0%;
    top: 0;
    bottom: 0;
    transition: .5s left ease-in-out;
  }
  &--expand {
    &:before {
      width: calc(100% - 24px);
    }
    &:after {
      left: calc(100% - 11px);
    }
  }
  &--invert {
    &:before {
      background-color: white !important;
    }
    &:after {
      background-image: url('http://cdn2.hubspot.net/hubfs/1608284/assets/images/hover-arrow-inverted.svg') !important;
    }
  }
}
