// Dependant on global.wrapIcons function
.social-link {
  display: inline-block;
  &__icon {
    display: block;
    position: relative;
    margin: em(5);
    background-color: $primary;
    padding: 0;
    width: 30px;
    height: 30px;
    font-weight: normal !important;
    span {
      display: none;
    }
    &:before {
      display: block;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translateX(-50%) translateY(-50%);
      transition: .3s;
      z-index: 1;
      color: inherit;
      font-family: FontAwesome;
      font-size: 16px !important;
      -webkit-font-smoothing: antialiased;
    }
    &[href*='facebook'] {
      &:before {
        content: '\f09a';
      }
    }
    &[href*='twitter'] {
      &:before {
        content: '\f099';
      }
    }
    &[href*='linkedin'] {
      &:before {
        content: '\f0e1';
      }
    }
    &[href*='plus.google'] {
      &:before {
        content: '\f0d5';
      }
    }
    &[href*='instagram'] {
      &:before {
        content: '\f16d';
      }
    }
    &[href*='youtube'] {
      &:before {
        content: '\f167';
      }
    }
    &[href*='mailto'] {
      &:before {
        content: '\f003';
      }
    }
    &:hover {
      &,
      &:before {
        color: #fff;
      }
    }
  }
}
