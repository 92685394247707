.widget-type-password_prompt {
  .hs-button {
    @extend %cta-btn;
    @extend %cta-btn--solid;
    @extend %cta-btn--small;
  }
  input[type='password'] {
    font-size: em(20);
    padding: em(20, 20) em(10, 20) !important;
    margin-bottom: em(15, 20);
    color: $gray-dark;
  }
}

.widget-type-email_subscriptions {
  input[type='checkbox'] {
    margin-top: 0 !important;
  }
  p {
    font-size: em(18);
  }
  p.header {
    margin-left: 0 !important;
  }
  label[for='globalunsub'] {
    margin-left: 0 !important;
    font-size: em(18);
  }
  input[type='submit'] {
    @extend %cta-btn;
    @extend %cta-btn--solid;
    @extend %cta-btn--small;
  }
}

.widget-type-email_subscriptions_confirmation {
  p {
    font-size: em(18);
  }
}

.widget-type-email_simple_subscription {
  .page-header {
    padding-bottom: 0 !important;
  }
  h1 {
    font-size: em(64);
    color: $gray-dark;
    line-height: 1 !important;
  }
  input[type='email'] {
    width: 100% !important;
  }
  input[type='submit'] {
    @extend %cta-btn;
    @extend %cta-btn--solid;
    @extend %cta-btn--small;
  }
}
