.hs_cos_gallery_main {
  margin-bottom: 0;
  padding-right: 0;
  padding-left: 0;
  .caption {
    position: absolute;
    right: 0;
    bottom: em(45, 21);
    left: 0;
    margin: auto;
    line-height: (25 / 21);
    letter-spacing: em(1, 21);
    color: white;
    font-family: $proxima;
    font-size: em(21);
    font-weight: 700;
  }
  .slick-dots {
    position: absolute;
    right: 0;
    bottom: em(25);
    left: 0;
    margin: 0;
    > li {
      margin: 0 4.5px;
      width: auto;
      height: auto;
      button {
        margin: 0;
        border: 1px solid white;
        border-radius: 50%;
        width: em(11);
        height: em(11);
        &:before {
          display: none;
        }
      }
      &.slick-active {
        button {
          background: white;
        }
      }
    }
  }
  .slick-slide img {
    width: 100vw;
    max-width: 1600px;
  }
  .slick-prev,
  .slick-next {
    transition: .25s opacity ease-in-out;
    opacity: 1;
    z-index: 1;
    background-image: url('//cdn2.hubspot.net/hubfs/1608284/assets/images/slider-arrow.svg');
    background-repeat: no-repeat;
    background-size: contain;
    width: em(42);
    height: em(42);
    font-size: inherit;
    @media (max-width: 767px) {
      display: none !important;
    }
    &:before {
      display: none;
    }
    &:hover {
      opacity: .7;
    }
  }
  .slick-prev {
    left: em(53);
    transform: rotate(180deg);
  }
  .slick-next {
    right: em(53);
  }
}
