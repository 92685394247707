.blog-subscribe-modal {
  &--open {
    @media (min-width: 992px) {
      position: fixed;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      background-color: rgba(white, .9);
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      z-index: 4;
    }
  }
  &:not(&--open) {
    display: none;
  }
  &__container {
    width: 100%;
    text-align: center;
    padding-left: em(30);
    padding-right: em(30);
  }
  &__inner {
    width: 100%;
    max-width: 852px;
    padding: em(23) em(90) em(53) em(68);
    background-color: white;
    box-shadow: 0 1px 11px rgba(140, 140, 140, .5);
    position: relative;
    z-index: 5;
    margin: auto;
    text-align: left;
  }
  &__close {
    z-index: 6;
    position: absolute;
    right: 0;
    top: 0;
    color: white;
    font-family: $proxima;
    font-size: em(24);
    font-weight: 800;
    line-height: (31 / 24);
    padding: em(14.5, 24) em(21.985, 24);
    background-color: $brown;
    transition: .25s background-color ease-in-out;
    border: 0;
    &:hover {
      background-color: darken($brown, 5%);
    }
    &:focus {
      outline: 0;
    }
  }
  &__title {
    @extend %partial-underline;
    color: $gray-dark;
    font-family: $proxima;
    font-size: em(36);
    font-weight: 600;
    line-height: (44 / 36);
    text-transform: uppercase;
    padding-bottom: em(15, 36);
    margin-bottom: em(19, 36);
    display: block;
    &:after {
      height: 2px;
    }
  }
  &__form {
    margin-top: em(35);
    input[type='submit'] {
      @extend %cta-btn;
      @extend %cta-btn--solid;
      @extend %cta-btn--small;
    }
  }
}
