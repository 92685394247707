%search {
  padding: 0 0 0 em(34, 18) !important;
  color: #8d8d8d;
  font-family: $proxima;
  font-size: em(18);
  font-style: italic;
  font-weight: 300;
  line-height: (22 / 18);
  transition: .25s all ease-in-out;
  background-color: transparent;
  background-image: url('//cdn2.hubspot.net/hubfs/1608284/assets/images/search.svg');
  background-position: 0% center;
  background-repeat: no-repeat;
  background-size: 1em 1em;
  border-top: 0 !important;
  border-left: 0 !important;
  border-right: 0 !important;
  border-bottom: 1px solid rgba($orange, 0) !important;
  &:focus {
    background-position: -100% center;
    border-bottom: 1px solid $orange !important;
    padding: 0 !important;
  }
}
