.product-material {
  display: flex;
  align-items: center;
  margin-bottom: em(20);
  &:hover {
    text-decoration: none;
    .product-material__title {
      color: $teal;
    }
  }
  &__icon {
    width: em(39);
    height: em(32);
    margin: 0 em(20) 0 0;
    position: relative;
  }
  &__image {
    position: absolute;
    width: 100%;
    height: 100%;
    object-fit: contain;
    object-position: center center;
    font-family: 'object-fit: contain; object-position: center center;';
  }
  &__title {
    color: $gray-dark;
    font-family: $proxima;
    font-size: em(18);
    font-weight: 600;
    letter-spacing: em(.5, 18);
    line-height: (22 / 18);
    transition: .25s color ease-in-out;
  }
}
