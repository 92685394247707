.collection-link-section {
  display: flex;
  width: 100%;
  position: relative;
  @media (max-width: 767px) {
    flex-direction: column;
  }
  &--reverse {
    flex-direction: row-reverse;
    .collection-link-section {
      &__container--large {
        @extend %corners;
        @extend %corners--top-left;
      }
      &__container--nest {
        @media (min-width: 768px) {
          left: 0;
        }
      }
      &__top,
      &__bottom {
        @media (min-width: 768px) {
          flex-direction: row-reverse;
        }
      }
    }
  }
  &:not(&--reverse) {
    .collection-link-section {
      &__container--large {
        @extend %corners;
        @extend %corners--top-right;
      }
      &__container--nest {
        @media (min-width: 768px) {
          right: 0;
        }
      }
    }
  }
  &__container {
    @media (max-width: 767px) {
      width: 100%;
    }
    @media (min-width: 768px) {
      width: 50%;
    }
    &--large {
      @media (max-width: 767px) {
        display: none;
      }
      .collection-link-section__image {
        padding-bottom: 100%;
        height: 0;
      }
    }
    &--nest {
      display: flex;
      flex-direction: column;
      @media (max-width: 767px) {
        height: 500px;
      }
      @media (min-width: 768px) {
        position: absolute;
        top: 0;
        height: 100%;
      }
    }
  }
  &__link,
  &__image {
    @media (max-width: 479px) {
      font-size: 8px;
    }
    @media (min-width: 480px) and (max-width: 767px) {
      font-size: 9px;
    }
    @media (min-width: 768px) and (max-width: 991px) {
      font-size: 9.5px;
    }
    @media (min-width: 992px) and (max-width: 1300px) {
      font-size: 12px;
    }
  }
  &__link {
    display: flex;
    background-color: white;
    padding: em(67);
    text-align: left;
    align-items: flex-start;
    flex-direction: column;
    justify-content: center;
    &:hover {
      text-decoration: none;
      .collection-link-section__arrow {
        @extend %arrow--expand;
      }
    }
    h3 {
      margin: 0;
    }
  }
  &__image {
    display: flex;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    padding: em(18) em(25);
    width: 100%;
    text-align: left;
    align-items: flex-start;
    flex-direction: column;
    justify-content: flex-end;
    &:hover {
      text-decoration: none;
      .collection-link-section__arrow {
        @extend %arrow--expand;
      }
    }
    &:not([href]) {
      cursor: default;
      pointer-events: none;
    }
    &-title {
      margin-bottom: em(11, 24);
      line-height: (29 / 24);
      color: white;
      font-family: $proxima;
      font-size: em(24);
      font-weight: 600;
    }
    .collection-link-section__arrow {
      @extend %arrow--invert;
    }
  }
  &__top,
  &__bottom {
    display: flex;
    flex-basis: 50%;
    .collection-link-section__image,
    .collection-link-section__link {
      width: 50%;
    }
  }
  &__type {
    margin-top: em(7, 21);
    margin-bottom: em(18, 21);
    text-transform: lowercase;
    line-height: (25 / 21);
    letter-spacing: em(.5, 21);
    color: $teal;
    font-family: $proxima;
    font-size: em(21);
    font-weight: 600;
  }
  &__arrow {
    @extend %arrow;
    margin: 0;
  }
  &__title,
  &__type {
    width: 100%;
  }
}
