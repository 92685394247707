input[type='text'],
input[type='email'],
input[type='phone'],
input[type='tel'],
input[type='number'],
input[type='file'],
input[type='password'],
textarea,
.hs_dropdown_select .input {
  outline: none;
  border: 1px solid #ccc;
  border-radius: 2px;
  padding: 8px;
  width: 100%;
  font-size: em(16);
  box-sizing: border-box;
  background-color: white;
  &:focus {}
}
textarea {
  min-height: em(137);
}

input[type='file'] {
  padding-top: 11px;
  padding-bottom: 11px;
  line-height: 1ex;
  font-size: 1em;
}

select {
  font-size: inherit;
  &::-ms-expand {
    display: none;
  }
}

input[type='submit'] {}

.form-title {
  &:not(:empty) {
    margin-bottom: .5em;
  }
}

.hs-form {
  fieldset {
    max-width: none;
  }
  .hs-form-field {
    margin-bottom: 1em;
    vertical-align: top;
    overflow-x: hidden;
    > label {
      display: block;
      margin-bottom: em(10);
      color: $gray-dark;
    }
  }
  .input {
    width: 100%;
  }
  .hs_dropdown_select {
    position: relative;
    .input {
      position: relative;
      overflow: hidden;
      select {
        outline: none;
        border: 0;
        background: transparent;
        width: 103% !important;
        font-size: inherit;
        appearance: none;
        box-sizing: border-box;
      }
      &:before {
        position: absolute;
        top: 50%;
        right: 20px;
        transform: translateY(-50%);
        background-image: url('//cdn2.hubspot.net/hubfs/1608284/assets/images/select-box.svg');
        background-size: contain;
        background-position: center center;
        width: em(12);
        height: em(7);
        content: '';
      }
    }
  }
  .hs-datepicker {
    transform: translateY(-1px);
    .pika {
      &-single {
        border: 1px solid $gray;
        box-shadow: none;
      }
      &-label {
        color: $gray-darker;
        font-size: 1em;
        font-weight: normal;
      }
      &-lendar {
        margin: 0;
        padding: 10px;
      }
      &-button {
        border-radius: 0;
        box-shadow: none;
        padding: 10px 5px;
        text-align: center;
        font-size: em(13);
        &:hover {
          border-radius: 0 !important;
          background-color: rgba($primary, .75) !important;
        }
      }
      &-table {
        th {
          font-size: em(13);
        }
        td {
          &:not(.is-today):not(.is-selected) .pika-button {
            background-color: #fff;
            color: $gray-dark;
          }
          &.is-today .pika-button {
            background-color: $gray-lighter;
            color: $primary;
          }
        }
      }
    }
    .is-selected {
      .pika-button {
        background-color: $primary;
        font-weight: normal;
        &:hover {
          background-color: $primary !important;
        }
      }
    }
    abbr {
      border-bottom: 0;
      text-decoration: none;
      color: $gray-darker;
      font-weight: normal;
    }
  }
  .hs-error-msgs {
    color: red;
    font-size: em(14);
  }
  ul.inputs-list {
    margin-top: 10px;
    margin-left: 0;
    padding-left: 0;
    list-style: none;
    input {
      margin-right: em(10);
    }
  }
  .hs-button {
    @extend %cta-btn;
    @extend %cta-btn--solid-with-border;
    @extend %cta-btn--small;
  }
}

// recaptcha responsiveness
.hs_recaptcha > div > div > div > iframe[src*='recaptcha'] {
  @media (max-width: 767px) {
    transform: scale(.8) translateX(-12.5%);
  }
}

// Hubspot Overrides
fieldset {
  .hs-input:not([type='checkbox']):not([type='radio']):not(select) {
    width: 100% !important;
  }
  > div {
    float: none !important;
  }
  @media (min-width: 768px) {
    &.form-columns-2 {
      > div {
        display: inline-block;
        float: none !important;
        width: 49% !important;
        &:last-child {
          float: right !important;
        }
      }
    }
    &.form-columns-3 {
      text-align: center;
      > div {
        display: inline-block;
        margin: 0 1%;
        width: 32% !important;
        text-align: left;
        &:first-child {
          margin-left: 0;
        }
        &:last-child {
          float: right !important;
          margin-right: 0;
        }
      }
    }
  }
  @media (max-width: 767px) {
    > div {
      display: block !important;
      width: 100% !important;
    }
  }
}
