.project-inspiration-item {
  width: 100%;
  height: 0;
  padding-bottom: 100%;
  position: relative;
  background-color: black;
  @media (max-width: 767px) {
    font-size: 9px;
  }
  @media (min-width: 768px) and (max-width: 991px) {
    font-size: 10px;
  }
  @media (min-width: 992px) and (max-width: 1199px) {
    font-size: 12px;
  }
  &:before,
  &:after {
    z-index: 3;
  }
  &:hover {
    text-decoration: none;
    .project-inspiration-item__underlay {
      opacity: .7;
    }
  }
  &__underlay,
  &__inner {
    position: absolute;
  }
  &__underlay {
    z-index: 1;
    background-size: cover;
    background-position: center center;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    opacity: .5;
    transform: scale(1);
    transition: .25s all ease-in-out;
    overflow: hidden;
  }
  &__inner {
    z-index: 2;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    text-align: left;
    width: 100%;
    height: auto;
    bottom: 0;
    left: 0;
    right: 0;
    padding: em(30) em(37);
  }
  &__header,
  &__products,
  &__styles {
    width: 100%;
    display: block;
  }
  &__header {
    display: block;
    color: white;
    font-family: $proxima;
    font-size: em(18);
    font-weight: 400;
    line-height: (22 / 18);
  }
  &__products,
  &__styles {
    list-style-type: none;
    margin: 0;
    padding: 0;
  }
  &__products {
    margin-top: em(7);
    margin-bottom: em(13);
    li {
      text-shadow: 0 em(2, 24) em(4, 24) rgba(0, 0, 0, .5);
      color: white;
      font-family: $bebas;
      font-size: em(24);
      font-weight: 400;
      line-height: (29 / 24);
      text-transform: uppercase;
      display: inline;
      &:not(:last-child) {
        &:after {
          content: ',';
          margin-right: em(5, 24);
        }
      }
    }
  }
  &__styles {
    position: relative;
    padding-top: em(18);
    margin-bottom: 0;
    &:before {
      content: '';
      position: absolute;
      width: 100%;
      height: 2px;
      max-width: em(150);
      background-color: $orange;
      top: 0;
      left: 0;
    }
    li {
      color: $gray-light;
      font-family: $proxima;
      font-size: em(14);
      font-weight: 700;
      line-height: (17 / 14);
      text-transform: uppercase;
      display: inline;
      &:not(:last-child) {
        &:after {
          content: '/';
          margin: 0 em(5, 14);
        }
      }
    }
  }
  &__details {
    display: none;
  }
}
