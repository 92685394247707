.site-footer {
  padding: 0;
  > .inner {
    @extend %constrain;
    @extend %constrain--full;
    position: relative;
    @media (max-width: 767px) {
      padding-right: 0;
      padding-left: 0;
    }
  }
  ul {
    margin: 0;
    padding: 0;
    list-style: none;
  }
  a {
    display: block;
    &:hover {
      text-decoration: none;
    }
  }
  &__column {
    padding-right: em(16.5);
    padding-left: em(16.5);
    @media (max-width: 767px) {
      flex-basis: 100%;
    }
    &--wide {
      @media (min-width: 768px) {
        flex-basis: 50%;
      }
      .hs-menu-depth-1 > ul {
        @media (min-width: 768px) and (max-width: 991px) {
          > li {
            display: inline-block;
            float: left;
            width: calc(50% - 16px);
            vertical-align: top;
            &:nth-child(2) {
              float: right;
            }
          }
        }
        @media (min-width: 992px) {
          display: flex;
          align-items: flex-start;
          > li {
            flex-grow: 1;
          }
        }
        @media (max-width: 991px) {
          flex-wrap: wrap;
        }
        > li {
          margin-right: em(16);
          text-align: left;
        }
      }
    }
    &--narrow {
      @media (min-width: 768px) and (max-width: 991px) {
        flex-basis: 25%;
      }
      @media (min-width: 992px) {
        flex-basis: 16.666%;
      }
      &:nth-child(3) {
        .hs-menu-depth-1:last-child {
          @media (min-width: 768px) and (max-width: 1200px) {
            margin-top: em(31);
          }
          @media (min-width: 1201px) and (max-width: 1299px) {
            margin-top: em(28);
          }
          @media (min-width: 1300px) {
            margin-top: em(53);
          }
        }
      }
      &:last-child {
        @media (max-width: 991px) {
          display: none;
        }
      }
    }
  }
  &__main-menu {
    @extend %constrain;
    @extend %constrain--wide;
    padding: em(60) 0 em(53);
    @media (min-width: 768px) and (max-width: 1300px) {
      padding-right: em(35);
      padding-left: em(35);
    }
    > div > ul {
      display: flex;
      @media (max-width: 767px) {
        flex-wrap: wrap;
      }
    }
    ul {
      text-align: left;
    }
    .hs-menu-depth-1 {
      > a {
        display: block;
        transition: .25s color ease-in-out;
        margin-bottom: em(17, 24);
        border-bottom: 2px solid rgba(#a4a9ad, .5);
        padding: 0 0 em(4, 24);
        width: 100%;
        text-align: left;
        text-transform: uppercase;
        line-height: (29 / 24);
        letter-spacing: em(1, 24);
        color: $gray-dark;
        font-family: $bebas;
        font-size: em(24);
        font-weight: 400;
        &:hover {
          color: $teal;
        }
        &:empty {
          display: none;
        }
        @media (max-width: 1299px) {
          font-size: 18px;
        }
      }
      @media (max-width: 767px) {
        position: relative;
        &:after {
          display: block;
          position: absolute;
          top: 4px;
          right: 3px;
          transition: .25s transform ease-in-out;
          background-image: url('//cdn2.hubspot.net/hubfs/1608284/assets/images/expand.svg');
          background-repeat: no-repeat;
          background-position: center center;
          background-size: contain;
          width: 7px;
          height: 12px;
          content: '';
        }
        .hs-menu-children-wrapper {
          transition: .25s opacity ease-in-out;
        }
        &:not(.expand) {
          &:after {
            transform: rotate(0deg);
          }
          .hs-menu-children-wrapper {
            opacity: 0;
            height: 0;
            overflow: hidden;
          }
        }
        &.expand {
          padding-bottom: em(10);
          &:after {
            transform: rotate(90deg);
          }
          .hs-menu-children-wrapper {
            opacity: 1;
            height: auto;
          }
        }
      }
    }
    .hs-menu-depth-2 {
      > a {
        transition: .25s color ease-in-out;
        margin-bottom: em(13, 14);
        line-height: (19 / 14);
        color: #48535a;
        font-family: $proxima;
        font-size: em(14);
        font-weight: 700;
        &:hover {
          color: $teal;
        }
      }
      > ul {
        margin-bottom: em(20);
      }
    }
    .hs-menu-depth-3 {
      margin-bottom: em(12);
      > a {
        @extend %standard-interior-link;
      }
    }
    .hs-menu-item {
      &:not(.hs-menu-depth-1) {
        > a {
          font-size: 14px;
        }
      }
    }
  }
  &__bottom {
    background-color: $brown;
    padding-top: em(29);
    padding-bottom: em(29);
    &-inner {
      @extend %constrain;
      @extend %constrain--wide;
      display: flex;
      align-items: center;
      @media (max-width: 767px) {
        flex-direction: column;
        justify-content: center;
      }
      @media (min-width: 768px) {
        justify-content: space-between;
      }
    }
  }
  &__social-icons {
    display: flex;
    @media (max-width: 767px) {
      &-outer {
        order: 0;
      }
    }
    li {
      &:not(:first-child) {
        margin-left: em(7.5);
      }
      &:not(:last-child) {
        margin-right: em(7.5);
      }
    }
    svg {
      @media (max-width: 339px) {
        width: 24px;
        height: 24px;
      }
      @media (min-width: 340px) and (max-width: 1199px) {
        width: 29px;
        height: 29px;
      }
    }
    a {
      .social-icon {
        &__white,
        &__brown {
          transition: .25s fill ease-in-out;
        }
        &__white {
          fill: white;
        }
        &__brown {
          fill: $brown;
        }
      }
      &:hover {
        .social-icon {
          &__white {
            fill: $brown;
          }
          &__brown {
            fill: white;
          }
        }
      }
    }
  }
  &__content {
    @media (max-width: 379px) {
      > span {
        justify-content: center;
      }
    }
    @media (max-width: 767px) {
      margin-top: 10px;
      order: 1;
      > span {
        flex-wrap: wrap;
      }
    }
    > span {
      display: flex;
      align-items: center;
    }
  }
  &__contact-text,
  &__phone-number {
    color: white;
    font-family: $proxima;
    font-size: em(18);
    @media (max-width: 379px) {
      width: 100%;
      display: block;
    }
  }
  &__contact-text {
    @media (min-width: 380px) {
      margin-right: em(42, 18);
    }
  }
  &__phone-number {
    transition: .25s color ease-in-out;
    font-weight: 700;
    &:hover {
      color: $teal;
    }
  }
}
