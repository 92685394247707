.project-inspiration-modal {
  &:not(&--open) {
    display: none;
  }
  &--open {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    display: flex;
    background-color: rgba(white, .95);
    align-items: center;
    justify-content: center;
    z-index: 12;
  }
  &__container {
    display: flex;
    flex-direction: column;
    width: 100%;
    text-align: center;
    @media (max-width: 767px) {
      padding-left: 30px;
      padding-right: 30px;
    }
    @media (min-width: 768px) {
      padding-left: em(65);
      padding-right: em(65);
    }
  }
  &__inner {
    width: 100%;
    max-width: 1600px;
    max-height: 80vh;
    display: flex;
    margin: auto;
    background-color: white;
    box-shadow: 0 em(2) em(34) rgba(black, .15);
    @media (max-width: 991px) {
      flex-direction: column;
    }
  }
  &__content {
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow-y: scroll;
    @media (max-width: 767px) {
      font-size: 9px;
    }
    @media (max-width: 991px) {
      width: 100%;
      order: 1;
    }
    @media (min-width: 992px) {
      width: 33.333%;
    }
    &-inner {
      width: 100%;
      text-align: left;
      @media (max-width: 767px) {
        padding: 20px;
      }
      @media (min-width: 768px) {
        padding: em(46) em(45);
      }
    }
  }
  &__photo {
    position: relative;
    overflow: hidden;
    @media (max-width: 991px) {
      width: 100%;
      height: 60vh;
      order: 0;
    }
    @media (min-width: 992px) {
      width: 66.666%;
      height: 80vh;
    }
  }
  &__image {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    object-fit: cover;
    @media (max-width: 991px) {
      object-position: center center;
      font-family: 'object-fit: cover; object-position: center center;';
    }
    @media (min-width: 992px) {
      object-position: right center;
      font-family: 'object-fit: cover; object-position: right center;';
    }
  }
  &__section-label,
  &__list {
    width: 100%;
    text-align: left;
  }
  &__section-label {
    display: block;
    color: #a4a9ad;
    font-family: $proxima;
    font-size: em(18);
    font-weight: 700;
    line-height: (19 / 18);
    text-transform: uppercase;
    margin-bottom: em(10, 18);
  }
  &__list {
    list-style-type: none;
    padding-left: 0;
    margin: 0 0 em(20);
    &:last-child {
      margin-bottom: 0;
    }
    li {
      display: inline;
      color: #2b353c;
      font-family: $proxima;
      font-size: em(18);
      font-weight: 700;
      line-height: (29 / 18);
      &:not(:last-child) {
        &:after {
          content: ',';
          margin-right: em(2.5);
          display: inline;
        }
      }
    }
  }
  &__controls {
    width: 100%;
    text-align: right;
    padding-bottom: em(8);
  }
  &__close,
  &__previous,
  &__next {
    background: transparent;
    border: 0;
    &:focus {
      outline: 0;
    }
  }
  &__close {
    @extend %close-btn;
  }
  &__previous,
  &__next {
    position: absolute;
    top: 0;
    bottom: 0;
    svg {
      width: em(15);
      height: em(25);
    }
  }
  &__previous {
    @media (max-width: 767px) {
      left: 5px;
    }
    @media (min-width: 768px) {
      left: em(25);
    }
  }
  &__next {
    @media (max-width: 767px) {
      right: 5px;
    }
    @media (min-width: 768px) {
      right: em(25);
    }
    svg {
      transform: rotate(180deg);
    }
  }
}

body.modal-open {
  overflow-y: hidden;
}
