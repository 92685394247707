.custom-sharing {
  padding-top: em(20);
  padding-bottom: em(20);
  text-align: center;
  h4 {
    margin: 0 em(10) 0 0;
  }
  ul {
    margin: 0;
    padding: 0;
    list-style: none;
    li {
      display: block;
      vertical-align: top;
      margin-bottom: 6px;
      a {
        display: block;
        position: relative;
        transition: .25s background-color ease-in-out;
        color: $brown;
        box-shadow: 0 1px 2px rgba(0, 0, 0, .5);
        background-color: white;
        &,
        svg {
          width: 39px;
          height: 39px;
        }
        svg {
          path {
            transition: .25s fill ease-in-out;
          }
          g {
            transition: .25s stroke ease-in-out;
          }
        }
        .social-icon {
          &__white {
            fill: white;
          }
          &__brown {
            fill: $brown;
          }
          &__stroke-brown {
            stroke: $brown;
          }
        }
        &:hover {
          background-color: $brown;
          .social-icon {
            &__white {
              fill: $brown;
            }
            &__brown {
              fill: white;
            }
            &__stroke-brown {
              stroke: white;
            }
          }
        }
      }
      &:first-child {
        margin-left: 0;
      }
      &:last-child {
        margin-right: 0;
      }
    }
  }
  @media (min-width: 768px) {
    display: flex;
    align-items: center;
  }
}
