.project-inspiration-resource {
  display: block;
  position: relative;
  width: 100%;
  height: 0;
  padding-bottom: 100%;
  background-color: white;
  @media (max-width: 767px) {
    font-size: 9px;
  }
  @media (min-width: 768px) and (max-width: 991px) {
    font-size: 10px;
  }
  @media (min-width: 992px) and (max-width: 1199px) {
    font-size: 12px;
  }
  &:hover {
    text-decoration: none;
    .project-inspiration-resource {
      &__arrow {
        @extend %arrow--expand;
      }
    }
  }
  &__inner {
    position: absolute;
    width: 100%;
    height: auto;
    bottom: 0;
    left: 0;
    right: 0;
    padding: em(30) em(37);
  }
  &__title,
  &__action-text {
    display: block;
    text-align: left;
    width: 100%;
  }
  &__title {
    color: $gray-dark;
    font-family: $bebas;
    font-size: em(32);
    font-weight: 400;
    letter-spacing: em(1, 32);
    line-height: (37 / 32);
    margin-bottom: em(13, 32);
  }
  &__action-text {
    color: $teal;
    font-family: $proxima;
    font-size: em(18);
    font-weight: 600;
    line-height: (22 / 18);
    margin-bottom: em(25, 18);
  }
  &__arrow {
    @extend %arrow;
    margin: 0;
  }
}
