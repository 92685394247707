%close-btn {
  color: $gray-dark;
  font-family: $proxima;
  font-size: em(21);
  font-weight: 700;
  line-height: (25 / 21);
  text-transform: uppercase;
  transition: .25s color ease-in-out;
  &:after {
    content: 'X';
    display: inline;
    margin-left: em(18, 21);
    color: $gray-dark;
  }
  &:hover {
    color: $teal;
    &:after {
      color: $gray-dark;
    }
  }
}