.browse-by-header {
  &__source {
    display: none;
  }
  &__top-nav {
    padding-top: 4px;
    @media (max-width: 767px) {
      display: none;
    }
    > div > ul {
      background-color: $gray-lightest;
      padding-left: em(50);
      padding-right: em(50);
      > li {
        &.active-branch {
          display: flex;
          align-items: center;
        }
      }
      li {
        margin-bottom: 0;
        &.hs-menu-depth-1 {
          &:not(.active-branch) {
            display: none;
          }
          &.active-branch {
            > a {
              font-weight: 600;
              padding-right: em(27, 18);
            }
          }
        }
        &.hs-menu-depth-2 {
          font-weight: 400;
          &.active {
            background-color: white;
            > a {
              &:hover {
                color: $gray-dark;
              }
            }
          }
        }
      }
      a {
        color: $gray-dark;
        font-family: $proxima;
        font-size: em(18);
        line-height: (22 / 18);
        padding: em(14, 18) em(15, 18);
        display: inline-block;
        transition: .25s color ease-in-out;
        &:hover {
          text-decoration: none;
          color: $teal;
        }
      }
    }
    ul {
      display: flex;
      list-style-type: none;
      margin: 0;
      padding: 0;
    }
  }
  &__inner {
    @media (max-width: 767px) {
      padding: em(58) em(20) em(43);
    }
    @media (min-width: 768px) {
      padding: em(58) em(65) em(43);
    }
  }
  &__title {
    margin-bottom: em(14, 64);
    text-align: left;
  }
  &__on-page-nav {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    list-style-type: none;
    margin: 0;
    padding: 0;
    li {
      display: inline-block;
      margin-bottom: em(12.5);
      &:not(:last-child) {
        margin-right: em(40);
      }
    }
    a {
      color: $teal;
      font-family: $proxima;
      font-size: em(18);
      font-weight: 400;
      line-height: (22 / 18);
      transition: .25s color ease-in-out;
      &:hover {
        text-decoration: none;
        color: darken($teal, 10%);
      }
    }
  }
}
