$gray-lightest: #f3f3f3;
$gray-lighter: #edeeef;
$gray-light: #d2d4d6;
$gray: #808285;
$gray-dark: #4d4d4d;
$gray-darker: #222;

$brown: #4f1b14;
$orange: #cf5300;
$teal: #5e9d95;

$primary: $orange;

$bebas: 'bebas-neue', sans-serif;
$proxima: 'proxima-nova', sans-serif;

body {
  position: relative;
  left: 0;
  transition: .25s left linear;
  text-align: center;
  font-size: 12px;
  font-family: $proxima;
  background-color: white;
  @media (min-width: 768px) {
    font-size: 14px;
  }
  @media (min-width: 992px) {
    font-size: 15px;
  }
  @media (min-width: 1200px) {
    font-size: 16px;
  }
}

hr {
  margin-top: em(30);
  margin-bottom: em(30);
}

.row-fluid [class*='span'] {
  min-height: 0 !important;
}

.overlay,
%overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  &--light {
    opacity: .2;
  }
  &--medium {
    opacity: .4;
  }
  &--dark {
    opacity: .6;
  }
  &--black {
    background-color: black;
  }
  &--brown {
    background-color: $brown;
  }
  &--teal {
    background-color: $teal;
  }
}

.constrain,
%constrain {
  float: none !important;
  margin: auto !important;
  padding-right: 30px;
  padding-left: 30px;
  max-width: 1230px;
  &--narrow {
    max-width: 860px;
  }
  &--wide {
    max-width: 1300px;
  }
  &--full {
    padding-right: 0;
    padding-left: 0;
    max-width: 1600px;
  }
}

.constrain {
  .page--narrow & {
    @extend %constrain--narrow;
  }
  .page--wide & {
    @extend %constrain--wide;
  }
}

.flex-top-full,
.flex-bottom-full {
  @extend %constrain;
  @extend %constrain--full;
}

.primary-content-container {
  text-align: left;
  &--padding-standard {
    padding-top: em(50);
    padding-bottom: em(50);
  }
  &--padding-large {
    padding-top: em(80);
    padding-bottom: em(80);
  }
}

.responsive-embed,
.responsive-video {
  position: relative;
  width: 100%;
  height: 0;
  padding-bottom: 56.25%;
  iframe,
  embed {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
  }
}

.primary-content {
  &--two-col {
    @media (min-width: 768px) {
      > .row-fluid-wrapper > .row-fluid {
        display: flex;
        justify-content: space-between;
        [class*='primary-content'] {
          width: 48%;
        }
      }
    }
  }
  &--two-col-right {
    @media (min-width: 992px) {
      > .row-fluid-wrapper > .row-fluid {
        display: flex;
        justify-content: space-between;
      }
      .primary-content__left-col {
        width: 76% !important;
        padding-right: em(60);
        ul {
          font-size: em(18);
          ul {
            font-size: inherit;
          }
        }
      }
      .primary-content__right-col {
        width: 20% !important;
      }
    }
  }
  &--two-col-left {
    @media (min-width: 768px) {
      > .row-fluid-wrapper > .row-fluid {
        flex-direction: row-reverse;
      }
      .primary-content__left-col {
        width: 71% !important;
      }
      .primary-content__right-col {
        width: 25% !important;
      }
    }
  }
}

%dynamic-menu {
  @media (max-width: 991px) {
    margin-top: em(50);
  }
  .hs-menu-depth-1:not(.active-branch) {
    display: none;
    visibility: hidden;
  }
  a {
    transition: .25s color ease-in-out;
    &:hover {
      color: $teal;
      text-decoration: none;
    }
  }
  li.active {
    > a {
      font-weight: bold;
    }
  }
  .hs-menu-depth {
    &-1 {
      > a {
        @extend %h5;
        @extend %partial-underline;
        padding-bottom: em(10, 28) !important;
        margin-bottom: em(15, 28) !important;
        display: block;
      }
      > ul {
        margin-left: 0 !important;
      }
    }
    &-2 {
      > a {
        @extend %standard-interior-link;
        font-size: em(20) !important;
        margin: em(10, 18) 0;
        display: block;
      }
      > ul {
        margin-left: em(20) !important;
      }
    }
    &-3 {
      > a {
        @extend %standard-interior-link;
        font-size: em(18) !important;
        display: block;
        margin: em(15, 18) 0 !important;
      }
    }
  }
}
