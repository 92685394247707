.project-inspiration {
  &__header {
    @media (max-width: 991px) {
      padding: em(56) em(30) em(32);
    }
    @media (min-width: 992px) {
      padding: em(56) em(70) em(32);
    }
    h1 {
      text-align: left;
      margin-bottom: em(22, 64);
    }
  }
  &__filtering {
    display: flex;
    @media (max-width: 991px) {
      flex-wrap: wrap;
    }
    @media (min-width: 992px) {
      align-items: center;
    }
    @media (min-width: 768px) and (max-width: 991px) {
      font-size: 12px;
    }
    @media (min-width: 992px) and (max-width: 1199px) {
      font-size: 11px;
    }
    @media (min-width: 1200px) and (max-width: 1299px) {
      font-size: 13px;
    }
    @media (min-width: 1300px) and (max-width: 1399px) {
      font-size: 14px;
    }
  }
  &__filters {
    display: flex;
    align-items: center;
    list-style-type: none;
    flex-grow: 1;
    flex-wrap: wrap;
    margin: 0;
    padding-left: 0;
    @media (min-width: 768px) {
      margin-left: em(30);
    }
    @media (max-width: 991px) {
      order: 2;
    }
    &-label {
      font-weight: 700;
      border-bottom: 2px solid white;
      @media (max-width: 767px) {
        width: 100%;
        text-align: left;
      }
      @media (max-width: 991px) {
        order: 1;
      }
    }
    li {
      margin: 0;
    }
  }
  &__filters-label,
  &__filter {
    color: $gray-dark;
    font-family: $proxima;
    font-size: em(18);
    line-height: (22 / 18);
  }
  &__filter {
    margin: 0 em(20, 18) 0 0;
    font-weight: 400;
    border: 0;
    background: transparent;
    padding: 0;
    border-bottom: 2px solid rgba($orange, 0);
    transition: .25s all ease-in-out;
    &:hover {
      color: $teal;
    }
    &:focus,
    &--active {
      outline: 0;
      &:hover {
        color: $gray-dark;
      }
    }
    &--active {
      border-bottom: 2px solid $orange;
    }
  }
  &__search {
    border: 0;
    background: transparent;
    flex-shrink: 0;
    @media (max-width: 991px) {
      order: 0;
      width: 100%;
      margin-bottom: em(20);
    }
    &-input {
      @extend %search;
      min-width: em(250, 18);
      &:focus {
        min-width: em(400, 18);
      }
    }
  }
  &__header,
  &__flexible,
  &__fancy {
    @extend %constrain;
    @extend %constrain--full;
  }
  &__flexible {
    > span {
      display: flex;
      flex-wrap: wrap;
      > div {
        @media (max-width: 349px) {
          width: 100%;
        }
        @media (min-width: 350px) and (max-width: 767px) {
          width: 50%;
        }
        @media (min-width: 768px) {
          width: 25%;
        }
      }
    }
  }
  &__fancy {
    display: flex;
    flex-wrap: wrap;
    &--half,
    &--four,
    &--row {
      display: flex;
      flex-wrap: wrap;
    }
    &--half {
      @media (max-width: 767px) {
        width: 100%;
      }
      @media (min-width: 768px) {
        width: 50%;
      }
      > div {
        width: 100%;
      }
    }
    &--four {
      @media (max-width: 767px) {
        width: 100%;
      }
      @media (min-width: 768px) {
        width: 50%;
      }
      > div {
        width: 50%;
      }
    }
    &--row {
      width: 100%;
      > div {
        @media (max-width: 767px) {
          width: 50%;
        }
        @media (min-width: 768px) {
          width: 25%;
        }
      }
    }
    .project-inspiration-item {
      &--featured {
        .project-inspiration-item__inner {
          padding: em(60) em(50);
        }
      }
    }
  }
  &__nest {
    &--filters-engaged {
      .project-inspiration {
        &__fancy {
          display: none;
        }
      }
    }
    &:not(&--filters-engaged) {
      .project-inspiration {
        &__flexible {
          display: none;
        }
      }
    }
  }
  &__empty-message {
    &--open {
      display: block;
    }
    &:not(&--open) {
      display: none;
    }
  }
}

@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

html {
  &:not(.hs-inline-edit) {
    .project-inspiration {
      &__flexible {
        > span > div {
          &:not(.filtered) {
            display: none;
          }
        }
      }
      &__nest {
        &--loading {
          min-height: 100vh;
          position: relative;
          &:before {
            position: absolute;
            width: 100%;
            height: 100%;
            background-color: white;
            top: 0;
            left: 0;
            bottom: 0;
            right: 0;
            z-index: 2;
          }
          .loader {
            margin: 60px auto;
            font-size: 10px;
            position: absolute;
            top: 25%;
            transform: translateY(-50%) translateZ(0);
            left: 0;
            right: 0;
            text-indent: -9999em;
            border-top: 1.1em solid rgba($gray-dark, .2);
            border-right: 1.1em solid rgba($gray-dark, .2);
            border-bottom: 1.1em solid rgba($gray-dark, .2);
            border-left: 1.1em solid $gray-dark;
            animation: spinner 1.1s infinite linear;
            &,
            &:after {
              border-radius: 50%;
              width: 10em;
              height: 10em;
            }
          }
        }
        &:not(.project-inspiration__nest--loading) {
          .loader {
            display: none;
          }
        }
      }
    }
  }
  &.hs-inline-edit {
    .project-inspiration__nest:not(.project-inspiration__nest--filters-engaged) .project-inspiration__flexible {
      display: block !important;
    }
    .loader {
      display: none !important;
    }
  }
}
