h1,
h2,
h3,
h4,
h5,
h6,
p,
%h1,
%h2,
%h3,
%h4,
%h5,
%h6,
%p {
  margin-top: 0;
}

h1,
%h1 {
  margin-bottom: em(10, 64);
  text-transform: uppercase;
  line-height: (65 / 64);
  letter-spacing: em(1, 64);
  font-family: $bebas;
  font-size: em(64);
  font-weight: 400;
  @media (max-width: 767px) {
    font-size: 39px;
  }
  &:not(.dark) {
    color: white;
  }
  &.dark {
    color: $gray-dark;
  }
}

h2,
%h2 {
  color: $gray-dark;
  &.sub {
    margin-bottom: em(10, 44);
    line-height: (53 / 44);
    font-family: $proxima;
    font-size: em(44);
    font-weight: 700;
  }
  &:not(.sub) {
    margin-bottom: em(10, 48);
    text-transform: uppercase;
    line-height: (57 / 48);
    letter-spacing: em(3, 48);
    font-family: $bebas;
    font-size: em(48);
    font-weight: 400;
  }
}

h3,
%h3 {
  margin-bottom: em(10, 42);
  text-transform: uppercase;
  line-height: (51 / 42);
  letter-spacing: em(1, 42);
  color: $gray-dark;
  font-family: $bebas;
  font-size: em(42);
}

h4,
%h4 {
  margin-bottom: em(10, 36);
  line-height: (44 / 36);
  color: $orange;
  font-family: $proxima;
  font-size: em(36);
  font-weight: 600;
}

h5,
%h5 {
  margin-bottom: em(8, 28);
  line-height: (34 / 28);
  color: $gray-dark;
  font-family: $proxima;
  font-size: em(28);
  font-weight: 600;
}

h6,
%h6 {
  margin-bottom: em(8, 22);
  line-height: (27 / 22);
  color: $gray-dark;
  font-family: $proxima;
  font-size: em(22);
  font-weight: 600;
}

p,
%p {
  margin-bottom: em(15, 18);
  line-height: (25 / 18);
  color: $gray-dark;
  font-family: $proxima;
  font-size: em(18);
}

ul,
ol {
  line-height: (25 / 18);
  font-family: $proxima;
  &.inline {
    list-style: none;
    li {
      display: inline-block;
      margin-right: em(10);
    }
  }
  li {
    margin-bottom: em(5, 18);
  }
}

a {
  text-decoration: none;
  color: $teal;
  &:hover {
    text-decoration: underline;
  }
}

%partial-underline {
  position: relative;
  padding-bottom: em(9, 14);
  &:after {
    display: block;
    position: absolute;
    bottom: 0;
    left: 0;
    background-color: $orange;
    width: 100%;
    max-width: em(45, 14);
    height: em(2, 14);
    content: '';
  }
}
