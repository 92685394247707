.product-detail {
  &__trigger {
    @extend %simple-menu-expander;
    margin-bottom: 0;
    &:after {
      transform: rotate(0deg) translateY(-50%);
    }
  }
  &__content {
    transition: .25s opacity ease-in-out;
    overflow: hidden;
    > p {
      margin-top: 0 !important;
      &:last-child {
        margin-bottom: 0 !important;
      }
    }
    > ul {
      list-style: square;
      margin-top: 0;
      padding-left: 1.25em;
      &:first-child {
        margin-top: 0;
      }
      &:last-child {
        margin-bottom: 0;
      }
      li {
        font-size: em(18);
      }
    }
  }
  &--expanded {
    .product-detail__trigger {
      border-bottom-color: $teal;
      &:after {
        transform: rotate(-45deg) translateY(-32%) translateX(55%);
      }
    }
    .product-detail__content {
      height: auto;
      opacity: 1;
      padding-top: em(15);
      padding-bottom: em(10);
    }
  }
  &:not(.product-detail--expanded) {
    .product-detail__content {
      height: 0;
      opacity: 0;
      p {
        margin: 0 !important;
      }
    }
  }
}
