.product-category-section {
  &__anchor {

  }
  &__inner {
    display: flex;
    @media (max-width: 767px) {
      flex-direction: column;
      padding: em(50) em(20);
    }
    @media (min-width: 768px) {
      padding: em(50) em(65);
    }
  }
  &__content {
    text-align: left;
    @media (max-width: 767px) {
      margin-bottom: em(60);
    }
    @media (min-width: 768px) and (max-width: 1199px) {
      width: 50%;
      padding-right: em(60);
    }
    @media (min-width: 1200px) {
      width: 40%;
      padding-right: em(115);
    }
  }
  &__title {
    font-family: $proxima !important;
    font-size: em(35) !important;
    font-weight: 700 !important;
    line-height: (42 / 35) !important;
    text-transform: none !important;
    margin-bottom: em(19, 35);
    letter-spacing: 0 !important;
  }
  &__description {
    margin-bottom: em(25);
  }
  &__menu {
    list-style-type: none;
    margin: 0;
    padding: 0;
    > li {
      > span {
        @extend %simple-menu-expander;
      }
      > a {
        @extend %simple-menu-link;
      }
    }
    .hs-menu-children-wrapper {
      display: none;
    }
  }
  &__photo {
    @extend %corners;
    @extend %corners--top-left;
    margin: 0;
    @media (min-width: 768px) and (max-width: 1199px) {
      width: 50%;
    }
    @media (min-width: 1200px) {
      width: 60%;
    }
  }
  &__image {
    width: 100%;
    height: auto;
  }
  &__more {
    display: none;
  }
}
