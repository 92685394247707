.blog-header {
  padding-top: em(55);
  padding-bottom: em(32);
  @media (max-width: 991px) {
    padding-left: em(30);
    padding-right: em(30);
  }
  @media (min-width: 992px) {
    padding-left: em(65);
    padding-right: em(65);
  }
  @media (min-width: 992px) and (max-width: 1099px) {
    font-size: 10px;
  }
  @media (min-width: 1100px) and (max-width: 1249px) {
    font-size: 12px;
  }
  @media (min-width: 1250px) and (max-width: 1399px) {
    font-size: 14px;
  }
  &__inner {
    display: flex;
    @media (max-width: 991px) {
      flex-wrap: wrap;
    }
    @media (min-width: 992px) {
      align-items: center;
    }
  }
  &__name {
    color: $gray-dark;
    font-family: $bebas;
    font-size: em(64);
    font-weight: 400;
    letter-spacing: em(1, 64);
    line-height: (65 / 64);
    margin-right: em(40, 64);
    @media (max-width: 991px) {
      order: 0;
      width: 120px;
      margin-bottom: 15px;
    }
  }
  &__topics {
    list-style-type: none;
    padding: 0;
    margin: 0;
    @media (max-width: 991px) {
      order: 3;
    }
    &-label {
      color: $gray-dark;
      font-family: $proxima;
      font-size: em(18);
      font-weight: 700;
      line-height: (22 / 18);
      margin-right: em(20, 18);
      @media (max-width: 991px) {
        order: 2;
      }
    }
    li {
      display: inline-block;
      margin-bottom: 0;
      @media (max-width: 767px) {
        &:nth-child(4),
        &:nth-child(5) {
          display: none;
        }
      }
    }
  }
  &__topics > li > a,
  &__more-topics--trigger {
    color: $gray-dark;
    font-family: $proxima;
    font-size: em(18);
    font-weight: 400;
    line-height: (22 / 18);
    margin-right: em(35, 18);
    transition: .25s color ease-in-out;
    &:hover {
      color: $teal;
      text-decoration: none;
    }
  }
  &__more-topics {
    position: relative;
    &--open {
      .blog-header__more-topics--trigger {
        color: $teal;
      }
      .blog-header__more-topics--content {
        transform: translateY(em(10));
      }
    }
    &:not(&--open) {
      .blog-header__more-topics--content {
        display: none;
      }
    }
    &--trigger {
      border: 0;
      background: transparent;
      padding: 0;
      &:focus {
        outline: 0;
      }
      svg {
        margin-left: em(5);
      }
    }
    &--content {
      background-color: white;
      position: absolute;
      top: 100%;
      z-index: 4;
      padding: em(30) em(20);
      box-shadow: 0 em(2) em(10) rgba(black, .15);
      column-gap: em(20);
      text-align: left;
      @media (max-width: 767px) {
        width: 200px;
        column-count: 2;
      }
      @media (min-width: 768px) and (max-width: 991px) {
        transform: translateY(em(10)) translateX(50%) !important;
        column-count: 3;
        width: 300px;
      }
      @media (max-width: 991px) {
        right: 0;
      }
      @media (min-width: 992px) {
        left: 0;
        width: 400px;
        column-count: 3;
      }
      li {
        display: block;
        a {
          text-transform: capitalize;
          color: $gray-dark;
          font-family: $proxima;
          font-size: em(14);
          font-weight: 400;
          line-height: em(22, 18);
          transition: .25s color ease-in-out;
          @media (max-width: 1099px) {
            font-size: 12px;
          }
          &:hover {
            color: $teal;
            text-decoration: none;
          }
        }
      }
    }
  }
  &__subscribe {
    display: inline-block;
    border: 0;
    background: transparent;
    margin-right: em(35);
    white-space: nowrap;
    @media (max-width: 991px) {
      display: none;
    }
    &:hover {
      svg {
        .change {
          fill: darken($orange, 10%);
        }
      }
      span {
        color: darken($orange, 10%);
      }
    }
    &:focus {
      outline: 0;
    }
    svg,
    span {
      vertical-align: middle;
    }
    svg {
      margin-right: em(8);
      .change {
        fill: $orange;
        transition: .25s fill ease-in-out;
      }
    }
    span {
      color: $orange;
      font-family: $proxima;
      font-size: em(18);
      font-weight: 600;
      line-height: em(22, 18);
      transition: .25s color ease-in-out;
    }
  }
  &__search {
    flex-grow: 1;
    overflow: hidden;
    text-align: right;
    @media (max-width: 991px) {
      order: 1;
      width: calc(100% - 155px);
      font-size: 12px;
      padding-top: 25px;
    }
    &-input {
      @extend %search;
      @media (max-width: 991px) {
        max-width: 140px !important;
      }
      @media (min-width: 992px) {
        max-width: 180px !important;
      }
      &:focus {
        max-width: 300px !important;
      }
    }
    input[type='submit'] {
      display: none !important;
    }
  }
}
