.colors {
  &__group {
    margin-bottom: em(20);

    p {
      a {
        color: $gray-dark;
        font-weight: 600;
      }
    }
  }

  &__title {
    margin-bottom: em(9, 22);
  }

  &__list {
    list-style-type: none;
    display: flex;
    flex-wrap: wrap;
    margin: 0;
    padding: 0;
  }

  &__color {
    position: relative;
    width: em(55);
    height: em(55);
    margin: 0 em(14) em(30) 0;
    background-size: cover;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.5);
    transform: translateY(0%);
    transition: .25s transform ease-in-out;
    text-align: center;

    &:after {
      position: absolute;
      display: inline-block;
      content: attr(data-name);
      opacity: 0;
      color: #2e2b2b;
      font-family: $proxima;
      font-size: em(15);
      font-weight: 400;
      line-height: (18 / 15);
      margin: auto;
      bottom: em(-27.5, 15);
      white-space: nowrap;
      transition: .25s opacity ease-in-out;
      left: 50%;
      transform: translateX(-50%);
    }

    &:hover {
      @media (min-width: 992px) {
        transform: translateY(-5px);

        &:after {
          opacity: 1;
        }
      }
    }

    &--active {
      @media (max-width: 991px) {
        transform: translateY(-5px);

        &:after {
          opacity: 1;
        }
      }
    }
  }

  &__modal {
    &--open {
      position: fixed;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      display: flex;
      background-color: rgba(white, 0.4);
      align-items: center;
      justify-content: center;
      z-index: 12;
    }

    &:not(&--open) {
      display: none;
    }

    &-inner {
      width: em(200);
    }

    &-box {
      width: 100%;
      height: auto;
      background-color: white;
      box-shadow: 0 5px 20px rgba(0, 0, 0, 0.25);
    }

    &-pattern {
      width: em(200);
      height: em(200);
      margin: 0;
      border-bottom: 1px solid #ccc;
      background-size: cover;
    }

    &-caption {
      font-size: em(18);
      padding: em(9, 18) em(18, 18);
      text-align: center;
    }

    &-actions {
      text-align: right;
      padding-bottom: em(5);
    }

    &-close {
      @extend %close-btn;

      border: 0;
      background: transparent;
      font-size: em(16);

      &:focus {
        outline: 0;
      }
    }
  }
}
