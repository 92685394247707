.quote {
  background-color: #f7f7f7;
  border-radius: 2px;
  padding: em(21) em(29);
  margin-top: em(36);
  margin-bottom: em(36);
  &__blockquote,
  &__name,
  &__title,
  &__blockquote:before,
  &__blockquote:after {
    color: $gray-dark;
    font-family: $proxima;
  }
  &__blockquote {
    position: relative;
    font-size: em(18);
    font-weight: 400;
    line-height: (25 / 18);
    padding: em(20, 18) em(44, 18) 0;
    margin: 0 0 em(23, 18);
    &:before,
    &:after {
      display: block;
      position: absolute;
      font-size: em(35, 18);
      font-weight: 700;
      line-height: (42 / 35);
    }
    &:before {
      content: '“';
      top: 0;
      left: 0;
    }
    &:after {
      content: '”';
      bottom: -.7em;
      right: 0;
    }
  }
  &__name,
  &__title {
    display: block;
    font-size: em(18);
    line-height: (22 / 18);
    padding-left: em(44, 18);
    padding-right: em(44, 18);
  }
  &__name {
    font-weight: 700;
  }
  &__title {
    font-weight: 400;
  }
}
