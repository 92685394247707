%corners,
.corners {
  position: relative;
  &:before,
  &:after {
    @media (min-width: 768px) {
      display: block;
      position: absolute;
      z-index: 1;
      background-color: $orange;
      content: '';
    }
  }
  &:before {
    width: 85%;
    @media (min-width: 768px) and (max-width: 991px) {
      height: 16px;
    }
    @media (min-width: 992px) and (max-width: 1199px) {
      height: 20px;
    }
    @media (min-width: 1200px) {
      height: 23px;
    }
  }
  &:after {
    height: 28.5%;
    min-height: em(164);
    @media (min-width: 768px) and (max-width: 991px) {
      width: 16px;
    }
    @media (min-width: 992px) and (max-width: 1199px) {
      width: 20px;
    }
    @media (min-width: 1200px) {
      width: 23px;
    }
  }
  &--top-left,
  &--top-right {
    &:before,
    &:after {
      @media (min-width: 768px) and (max-width: 991px) {
        top: -16px;
      }
      @media (min-width: 992px) and (max-width: 1199px) {
        top: -20px;
      }
      @media (min-width: 1200px) {
        top: -23px;
      }
    }
  }
  &--bottom-left,
  &--bottom-right {
    &:before,
    &:after {
      @media (min-width: 768px) and (max-width: 991px) {
        bottom: -16px;
      }
      @media (min-width: 992px) and (max-width: 1199px) {
        bottom: -20px;
      }
      @media (min-width: 1200px) {
        bottom: -23px;
      }
    }
  }
  &--top-left,
  &--bottom-left {
    &:before,
    &:after {
      @media (min-width: 768px) and (max-width: 991px) {
        left: -16px;
      }
      @media (min-width: 992px) and (max-width: 1199px) {
        left: -20px;
      }
      @media (min-width: 1200px) {
        left: -23px;
      }
    }
  }
  &--top-right,
  &--bottom-right {
    &:before,
    &:after {
      @media (min-width: 768px) and (max-width: 991px) {
        right: -16px;
      }
      @media (min-width: 992px) and (max-width: 1199px) {
        right: -20px;
      }
      @media (min-width: 1200px) {
        right: -23px;
      }
    }
  }
}
