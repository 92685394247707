.blog {
  // Use {% if is_listing_view %}blog__listing{% else %}blog__post{% endif %} in body class
  .primary-content-container {
    @extend %constrain;
    @extend %constrain--full;
  }
  &__listing {
    display: flex;
    flex-wrap: wrap;
    > .post {
      @media (max-width: 479px) {
        width: 100%;
      }
      @media (min-width: 480px) and (max-width: 767px) {
        width: 50%;
      }
      @media (min-width: 768px) and (max-width: 1199px) {
        width: 33.333%;
      }
      @media (max-width: 1199px) {
        &:nth-child(odd) {
          background-color: white;
        }
        &:nth-child(even) {
          background-color: $gray-lighter;
          .inner:before {
            border-color: transparent transparent $gray-lighter;
          }
        }
      }
      @media (min-width: 1200px) {
        width: 25%;
        &:nth-child(1),
        &:nth-child(3),
        &:nth-child(6),
        &:nth-child(8),
        &:nth-child(9),
        &:nth-child(11) {
          background-color: white;
        }
        &:nth-child(2),
        &:nth-child(4),
        &:nth-child(5),
        &:nth-child(7),
        &:nth-child(10),
        &:nth-child(12) {
          background-color: $gray-lighter;
          .inner:before {
            border-color: transparent transparent $gray-lighter;
          }
        }
      }
    }
  }
  &__post {}
  &__header {
    @extend %constrain;
    @extend %constrain--narrow;
    padding-bottom: em(45);
    text-align: left;
    .custom-sharing {
      padding-top: 0;
    }
  }
  &__featured-image {
    width: 100%;
    margin-bottom: em(60);
    img {
      width: 100%;
      height: auto;
    }
  }
  &__topics {
    display: flex;
    align-items: center;
    list-style-type: none;
    margin: 0;
    padding: 0;
    li {
      color: $teal;
      font-family: $proxima;
      font-size: em(21);
      font-weight: 400;
      line-height: (25 / 21);
      &,
      &:after {
        display: inline;
      }
      &:not(:last-child):after {
        content: ',';
        margin-right: em(3, 21);
      }
    }
  }
  &__content {
    &-inner {
      position: relative;
      display: block;
      > span {
        display: block;
        text-align: left;
        @media (min-width: 768px) {
          padding-left: em(80);
          padding-right: em(80);
        }
        & > *:not(.has-image) {
          @extend %constrain;
          @extend %constrain--narrow;
          margin: 0 auto em(25) !important;
        }
        & > .has-image {
          @extend %constrain;
          @extend %constrain--wide;
          margin-top: em(70) !important;
          margin-bottom: em(70) !important;
          img {
            width: 100% !important;
            height: auto;
            margin-bottom: em(10);
          }
        }
        .is-empty {
          display: none !important;
        }
        hr {
          display: none;
        }
      }
    }
  }
  &__pagination {
    display: flex;
    position: relative;
    justify-content: center;
    padding: em(37) em(20);
    &--prev {
      svg {
        transform: rotate(180deg);
        margin-right: em(7);
      }
    }
    &--next {
      svg {
        margin-left: em(7);
      }
    }
    a {
      color: $gray;
      font-family: $proxima;
      font-size: em(15);
      font-weight: 700;
      line-height: (18 / 15);
      text-transform: uppercase;
      margin: 0 em(16, 15);
      display: flex;
      align-items: center;
      transition: .25s color ease-in-out;
      &:hover {
        text-decoration: none;
        &:not(.disabled) {
          color: $teal;
        }
      }
      &.disabled {
        opacity: .4;
        pointer-events: none;
      }
    }
  }
  &__date {
    color: $gray;
    font-family: $proxima;
    font-size: em(28);
    font-weight: 400;
    line-height: (34 / 28);
    margin-bottom: em(13, 28);
    display: block;
  }
  &__comments {
    @extend %constrain;
    @extend %constrain--narrow;
    display: block;
    padding: em(30) 0 em(119);
    text-align: left;
    > div {
      padding-left: 30px;
      padding-right: 30px;
    }
    #comment-form {
      background-color: white;
      &:before {
        @extend %h4;
        display: block;
        margin-bottom: em(10);
        content: 'Leave a Comment';
        color: $gray-dark;
      }
      .actions {
        text-align: left;
      }
    }
    #captcha_wrapper {
      width: 100%;
      overflow: hidden;
    }
    input[type='submit'] {
      @extend %cta-btn;
      @extend %cta-btn--solid;
      @extend %cta-btn--small;
    }
  }
  &__related-posts {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    &,
    &-header {
      @extend %constrain;
      @extend %constrain--full;
    }
    &-header {
      width: 100%;
      color: $gray-dark;
      text-align: center;
      padding-bottom: em(50, 36);
      display: block;
    }
    > div {
      @media (max-width: 479px) {
        width: 100%;
        &:nth-child(3),
        &:nth-child(4) {
          display: none;
        }
      }
      @media (min-width: 480px) and (max-width: 767px) {
        width: 50%;
        &:nth-child(3),
        &:nth-child(4) {
          display: none;
        }
      }
      @media (min-width: 768px) and (max-width: 1199px) {
        width: 33.333%;
        &:nth-child(4) {
          display: none;
        }
      }
      @media (min-width: 1200px) {
        width: 25%;
      }
      &:nth-child(odd) {
        background-color: white;
      }
      &:nth-child(even) {
        background-color: $gray-lighter;
        .inner:before {
          border-color: transparent transparent $gray-lighter;
        }
      }
    }
  }
}

.author {
  @extend %constrain;
  @extend %constrain--narrow;
  margin-top: em(30) !important;
  margin-bottom: em(70) !important;
  background-color: white;
  text-align: center;
  display: flex;
  @media (max-width: 767px) {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  @media (min-width: 768px) {
    text-align: left;
    &__photo {
      margin-right: em(76);
    }
  }
  &__photo {
    display: inline-block;
    margin-bottom: em(20);
    width: em(250);
    img {
      margin-bottom: 0;
      width: em(250);
      height: auto;
    }
  }
  &__header {
    @extend %constrain;
    @extend %constrain--narrow;
    color: $gray-dark;
  }
  &__bio {}
  &__info {
    margin-bottom: em(10);
    @media (min-width: 768px) {
      display: flex;
      align-items: center;
      h4 {
        margin: 0;
      }
    }
  }
  &__name {
    @media (min-width: 768px) {
      margin-right: em(15);
    }
  }
  &__social-links {
    padding: 0;
  }
}
