.browse-by-style {
  display: flex;
  @media (max-width: 767px) {
    flex-direction: column;
    &__image {
      height: 295px;
    }
    &__content {
      padding: 33px 27px;
    }
  }
  @media (max-width: 991px) {
    &__image {
      &:before,
      &:after {
        // remove corners
        display: none;
      }
    }
  }
  @media (min-width: 768px) and (max-width: 991px) {
    &__content {
      padding: 25px;
    }
  }
  @media (min-width: 768px) {
    &__image,
    &__content {
      width: 50%;
    }
  }
  @media (min-width: 992px) {
    &__content {
      padding: em(70) em(110);
    }
  }
  &__image {
    @extend %corners;
    @extend %corners--bottom-right;
    background-position: center center;
    background-size: cover;
  }
  &__content {
    text-align: left;
    &-top {
      margin-bottom: em(45);
    }
    h4 {
      color: $gray-dark;
    }
  }
  &__menu {
    margin: 0;
    padding: 0;

    list-style-type: none;
    a {
      @extend %simple-menu-link;
    }
  }
}
