.accordion {
  &-group {
    margin: em(60) 0 em(30);
  }
  &-section {
    &--open {
      .accordion-section__content {
        height: auto;
        opacity: 1;
        padding: em(5) 0;
      }
      .accordion-section__header {
        border-bottom-color: $teal;
        &:after {
          transform: rotate(-45deg) translateY(-32%) translateX(55%);
        }
      }
    }
    &:not(&--open) {
      .accordion-section__content {
        height: 0;
        overflow: hidden;
        opacity: 0;
      }
      .accordion-section__header {
        &:after {
          transform: rotate(0deg) translateY(-50%);
        }
      }
    }
    &__header {
      @extend %simple-menu-expander;
    }
    &__content {
      transition: .5s opacity ease-in-out;
    }
  }
}
