$menu-width: 271px;

body.menu-open {
  @media (max-width: 991px) {
    position: relative;
    left: -$menu-width;
    overflow-y: hidden;
  }
  .site-header {
    @media (max-width: 991px) {
      left: -$menu-width;
    }
  }
}

.menu-trigger__container {
  display: flex;
  background-color: white;
  padding: 12px 10px;
  align-items: center;
  &:not(.open) {
    .on {
      display: none;
    }
  }
  &.open {
    .off {
      display: none;
    }
  }
  .on,
  .off {
    display: flex;
    align-items: center;
    span {
      @media (max-width: 767px) {
        display: none;
      }
      @media (min-width: 768px) and (max-width: 991px) {
        margin-left: 10px;
        line-height: 17px;
        color: $orange;
        font-family: $proxima;
        font-size: 14px;
        font-weight: 700;
      }
    }
  }
  .on {
    svg {
      width: 17px;
      height: 20px;
    }
  }
  @media (min-width: 992px) {
    display: none;
    visibility: hidden;
  }
}

.menu-trigger__button {
  outline: none;
  border: 0;
  background: none;
  @media (max-width: 767px) {
    width: 41px;
  }
}

.mobile-menu {
  position: fixed;
  top: 0;
  right: 0;
  transform: translateX(0%);
  transition: .25s all linear;
  z-index: -1;
  background-color: white;
  padding: 23px 33px;
  width: $menu-width;
  height: 100%;
  overflow: scroll;
  text-align: center;
  @media (min-width: 992px) {
    display: none;
  }
  &:not(.open) {
    transform: translateX(100%);
  }
  .inner {
    position: absolute;
    top: 23px;
    right: 33px;
    bottom: 43px;
    left: 33px;
    width: calc(100% - 66px);
    height: calc(100% - 66px);
    overflow-y: scroll;
  }
  ul {
    margin: 0;
    padding: 0;
    list-style: none;
  }
  a {
    &:hover {
      text-decoration: none;
    }
  }
  &__close-btn {
    position: relative;
  }
  &__main {
    text-align: left;
    .hs-menu-depth-1 {
      display: block;
      padding-bottom: 10px;
      > a {
        color: $gray-dark;
        font-family: $proxima;
        font-size: 16px;
        font-weight: 700;
      }
      &:nth-child(1) {
        > ul {
          padding-top: 10px;
          width: 100%;
          &:after {
            display: block;
            clear: both;
            content: '';
          }
          > li {
            display: block;
            vertical-align: top;
            &:nth-child(1),
            &:nth-child(2) {
              margin-bottom: 15px;
            }
          }
        }
      }
      &:nth-child(4) {
        display: none;
      }
    }
    .hs-menu-depth-2 {
      > a {
        @extend %partial-underline;
        display: block;
        padding-bottom: 4px;
        letter-spacing: .36px;
        color: $gray-dark;
        font-family: $proxima;
        font-size: 12px;
        font-weight: 600;
      }
      > ul {
        padding-top: 5px;
      }
    }
    .hs-menu-depth-3 {
      > a {
        color: #767f84;
        font-family: $proxima;
        font-size: 10px;
        font-weight: 400;
      }
    }
  }
  &__secondary {
    text-align: left;
    li {
      padding-top: 6px;
      padding-bottom: 6px;
      svg {
        margin-right: 3px;
      }
      span {
        line-height: 15px;
        color: #4a4a4a;
        font-family: $proxima;
        font-size: 12px;
        font-weight: 400;
      }
    }
  }
  &__bottom {
    position: absolute;
    right: 0;
    bottom: 15px;
    left: 0;
    line-height: 17px;
    color: #4a4a4a;
    font-family: $proxima;
    font-size: 14px;
    font-weight: 400;
    a {
      margin-left: 9px;
      color: $orange;
    }
  }
}
