.nested-layout {
  &__grid {
    &,
    span {
      @media (min-width: 768px) {
        display: flex;
        justify-content: space-between;
        &.nested-layout--main {
          flex-wrap: wrap;
        }
        &.nested-layout--r-to-l {
          flex-direction: row-reverse;
        }
      }
    }
  }
  &__content {}
  &__photo {
    img {
      width: 100%;
    }
  }
  &__column {
    position: relative;
  }
  @media (min-width: 768px) {
    &--one-col {
      span > div,
      .nested-layout__column {
        width: 100%;
      }
    }
    &--two-col {
      span > div,
      .nested-layout__column {
        width: calc(50% - 20px);
      }
    }
    &--three-col {
      span > div,
      .nested-layout__column {
        width: calc(33.3% - 20px);
      }
    }
    &--four-col {
      span > div,
      .nested-layout__column {
        width: calc(25% - 20px);
      }
    }
  }
  &--hide {
    display: none;
    visibility: hidden;
  }
}
