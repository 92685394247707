.lp {
  &--one-col {
    background-color: black;
    @media (max-width: 767px) {
      padding: em(58) em(30);
    }
    @media (min-width: 768px) {
      padding: em(58);
    }
    .custom-sharing {
      padding-top: em(62);
      &,
      ul {
        text-align: center;
      }
      a,
      svg {
        width: 27px;
        height: 27px;
      }
    }
  }
  &--two-col {
    .custom-sharing {
      display: block;
      width: 100%;
      text-align: left;
      &:before {
        content: 'Share';
        display: block;
        width: 100%;
        color: #a4a9ad;
        font-family: $proxima;
        font-size: em(18);
        font-weight: 700;
        line-height: (19 / 18);
        text-align: left;
        padding-bottom: em(16, 18);
        text-transform: uppercase;
      }
      ul {
        margin: 0 !important;
        display: block;
        width: 100%;
      }
    }
  }
  &__side-by-side {
    > div > div {
      display: flex;
      @media (max-width: 991px) {
        flex-direction: column;
      }
    }
    &--left-col,
    &--right-col {
      text-align: left;
      @media (min-width: 992px) {
        width: 50%;
      }
    }
    &--left-col {
      @extend %corners;
      @extend %corners--top-right;
      background-color: black;
      position: relative;
      @media (max-width: 991px) {
        order: 1;
      }
      &-inner {
        @media (max-width: 991px) {
          padding: em(68);
        }
        @media (min-width: 992px) and (max-width: 1199px) {
          padding: em(34);
        }
        @media (min-width: 1200px) {
          padding: em(68);
        }
        position: relative;
        z-index: 2;
        h1,
        h2,
        h3,
        h4,
        h5,
        h6,
        p,
        label {
          color: white !important;
        }
        p {
          font-size: em(16);
        }
        label {
          font-size: em(14);
          white-space: nowrap;
          @media (min-width: 992px) and (max-width: 1300px) {
            font-size: em(13) !important;
          }
        }
      }
    }
    &--right-col {
      background-color: white;
      @media (max-width: 991px) {
        padding: em(65);
        order: 0;
      }
      @media (min-width: 992px) and (max-width: 1199px) {
        padding: em(34) em(34) em(34) em(65);
      }
      @media (min-width: 1200px) {
        padding: em(65) em(81) em(65) em(100);
      }
    }
  }
  &__left-column-underlay {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 1;
    background-size: cover;
    background-position: center center;
    opacity: .3;
  }
  &__billboard {
    position: relative;
    width: 100%;
    padding: em(40) em(50);
    background-color: black;
    &--underlay {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      z-index: 1;
      background-size: cover;
      background-position: center center;
      background-repeat: no-repeat;
      opacity: .9;
    }
    &--inner {
      text-align: left;
      padding-bottom: em(195);
      position: relative;
      z-index: 2;
    }
    &--logo {
      margin-bottom: em(64);
      img {
        height: em(29);
        width: auto;
      }
    }
    &--content {
      width: 100%;
      max-width: 852px;
    }
  }
  &__fixed-background {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-size: cover;
    z-index: -1;
    opacity: .7;
  }
  &__simple-logo {
    text-align: center;
    margin-bottom: em(63);
  }
  &__simple-box {
    @extend %corners;
    @extend %corners--bottom-right;
    background-color: white;
    width: 100%;
    max-width: 629px;
    margin: auto;
    text-align: left;
    padding: em(41) em(44);
    position: relative;
    label {
      font-size: em(14);
    }
    p {
      font-size: em(16);
      margin-bottom: em(30);
      line-height: (25 / 16);
    }
  }
  &__copyright {
    width: 100%;
    background-color: $brown;
    padding: em(39) em(30);
    text-align: center;
    &-text {
      color: white;
      text-align: center;
      margin: 0 auto;
      font-size: em(14);
    }
  }
  .actions {
    padding-top: em(20);
  }
  input[type='submit'] {
    @extend %cta-btn--large;
  }
  h2 {
    @extend %partial-underline;
    display: block;
    font-size: em(35);
    padding-bottom: em(10, 35);
    margin-bottom: em(15, 35);
    &:after {
      height: 2px;
      max-width: 98px;
    }
  }
  .quote {
    max-width: 444px;
  }
  .custom-sharing {
    ul {
      margin: 0 auto;
      li {
        display: inline-block;
        margin: 0 em(5);
      }
    }
  }
  .body-container-wrapper,
  .footer-container-wrapper {
    @extend %constrain;
    @extend %constrain--full;
  }
}
