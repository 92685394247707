%simple-menu-link {
  display: block;
  position: relative;
  transition: .25s border-bottom ease-in-out;
  margin-bottom: em(10, 18);
  padding: em(13, 18) em(25, 18) em(13, 18) 0;
  width: 100%;
  line-height: (22 / 18);
  letter-spacing: em(.5, 18);
  color: $gray-dark;
  font-family: $proxima;
  font-size: em(18);
  font-weight: 600;
  &:after {
    display: block;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    transition: .25s right ease-in-out;
    background-image: url('//cdn2.hubspot.net/hubfs/1608284/assets/images/browse-arrow.svg');
    background-repeat: no-repeat;
    background-position: center center;
    background-size: contain;
    width: em(10, 18);
    height: em(14, 18);
    content: '';
  }
  &:not(:hover) {
    border-bottom: 2px solid $orange;
    &:after {
      right: em(11, 18);
    }
  }
  &:hover {
    border-bottom: 2px solid $teal;
    text-decoration: none;
    &:after {
      right: 0;
    }
  }
}

%simple-menu-expander {
  display: block;
  position: relative;
  transition: .25s border-bottom ease-in-out;
  margin-bottom: em(10, 18);
  padding: em(13, 18) em(25, 18) em(13, 18) 0;
  width: 100%;
  line-height: (22 / 18);
  letter-spacing: em(.5, 18);
  color: $gray-dark;
  font-family: $proxima;
  font-size: em(18);
  font-weight: 600;
  > a {
    font-size: inherit;
    color: $gray-dark;
    transition: .25s color ease-in-out;
    pointer-events: none;
  }
  &:not(:hover) {
    border-bottom: 2px solid $orange;
  }
  &:hover {
    border-bottom: 2px solid $teal;
    text-decoration: none;
  }
  &:after {
    position: absolute;
    content: '+';
    top: 50%;
    transform: translateY(-50%);
    color: $gray-dark;
    font-family: $proxima;
    font-size: em(24);
    font-weight: 900;
    line-height: 1;
    transition: .25s transform ease-in-out;
    right: em(7.5, 24);
  }
  &.metal-roofing-and-siding-menu__expander--open {
    &:after {
      transform: rotate(-45deg) translateY(-32%) translateX(55%);
    }
  }
  &:not(.metal-roofing-and-siding-menu__expander--open) {
    &:after {
      transform: rotate(0deg) translateY(-50%);
    }
  }
}
