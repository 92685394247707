// Custom Form
#cse-search-box {
  input[type='submit'] {
    @extend %cta-btn;
    margin-top: 1em;
  }
  &.inline {
    display: flex;
    align-items: stretch;
    input[type='submit'] {
      margin: 0;
    }
  }
}

.cse .gsc-search-button input.gsc-search-button-v2,
input.gsc-search-button-v2 {
  height: 26px !important;
  margin-top: 0 !important;
  min-width: 13px !important;
  padding: 5px 26px !important;
  width: 68px !important;
}

.gsc-input-box {
  height: 26px !important;
}

// Results
.gsc-control-cse {
  padding: 1em 0 !important;
  font-size: 16px !important;
  .gsc-above-wrapper-area {
    margin-bottom: 1em !important;
  }
  .gsc-webResult {
    &:not(:last-child) {
      margin-bottom: em(15);
      border-bottom: 1px solid $gray-lighter;
    }
  }
  .gsc-result-info,
  .gsc-orderby-container {
    font-size: em(13) !important;
  }
  div.gs-title {
    height: auto !important;
  }
  a.gs-title {
    display: block !important;
    padding-bottom: em(5) !important;
    height: auto !important;
    line-height: 1.5 !important;
    font-size: em(18) !important;
    &,
    b {
      color: $primary !important;
    }
    b {
      font-size: inherit !important;
    }
    &:hover {
      &,
      b {
        color: darken($primary, 10%) !important;
      }
    }
  }
  .gs-webResult {
    &:not(:last-child) {
      margin-bottom: em(10);
    }
  }
  .gs-visibleUrl {
    color: $gray-dark !important;
    font-size: em(16) !important;
    font-style: italic;
  }
  .gsc-table-result,
  .gsc-thumbnail-inside,
  .gsc-url-top {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .gsc-table-result {
    line-height: 1.4 !important;
    font-size: em(16) !important;
    @media (max-width: 600px) {
      &,
      tr,
      td {
        display: block !important;
      }
    }
  }
  .gsc-cursor-box {
    margin: 1em 0 !important;
    font-size: em(16) !important;
    .gsc-cursor {
      &-page {
        color: $gray-dark !important;
      }
      &-current-page {
        color: $primary !important;
      }
    }
  }
  .gs-image-box {
    margin-right: em(15) !important;
    width: em(200) !important;
    height: auto !important;
    .gs-image {
      width: 100% !important;
      max-width: 100% !important;
      max-height: none !important;
    }
    @media (max-width: 600px) {
      margin: em(10) 0 !important;
      width: 100% !important;
    }
  }
  div[class*='gsc-adBlock'] {
    border: 0 !important;
    filter: grayscale(100%);
  }
  .gsc-wrapper {
    &.gsc-thinWrapper {
      border: 0;
    }
  }
}
