.photo-and-content {
  display: flex;
  @media (max-width: 767px) {
    flex-direction: column;
  }
  @media (min-width: 768px) {
    align-items: stretch;
  }
  &--photo-first {
    .photo-and-content__photo {
      @extend %corners;
      @extend %corners--top-right;
    }
    .photo-and-content__content {
      @media (min-width: 992px) {
        padding-left: em(85);
      }
    }
  }
  &--content-first {
    @media (min-width: 768px) {
      flex-direction: row-reverse;
    }
    .photo-and-content__photo {
      @extend %corners;
      @extend %corners--top-left;
    }
  }
  &__photo,
  &__content {
    @media (max-width: 767px) {
      width: 100%;
      height: auto;
    }
    @media (min-width: 768px) {
      width: 50%;
    }
  }
  &__photo {
    position: relative;
    @media (max-width: 767px) {
      height: 320px;
      &:before {
        // mobile corner
        display: block;
        position: absolute;
        top: -12px;
        left: 0;
        background-color: $orange;
        width: 68.4%;
        max-width: 219px;
        height: 12px;
        content: '';
      }
    }
  }
  &__image {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center center;
  }
  &__content {
    background-color: white;
    text-align: left;
    @media (max-width: 767px) {
      padding: 33px 27px;
    }
    @media (min-width: 768px) and (max-width: 991px) {
      padding: 35px;
    }
    @media (min-width: 992px) {
      padding: em(60) em(120) em(60) em(53);
    }
    h2 {
      @extend %partial-underline;
      margin-bottom: em(22, 35);
      padding-bottom: em(10, 35);
      text-transform: none;
      line-height: (42 / 35);
      letter-spacing: 0;
      font-family: $proxima;
      font-size: em(35);
      font-weight: 700;
      &:after {
        max-width: 100px;
        height: 2px;
      }
    }
  }
}
