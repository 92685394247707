.featured-resource {
  display: flex;
  @media (max-width: 767px) {
    margin: em(30) 0;
  }
  @media (min-width: 768px) and (max-width: 1199px) {
    margin: em(14) em(20) 0;
  }
  @media (min-width: 1200px) {
    margin: em(30) em(30) 0;
  }
  &:hover {
    text-decoration: none;
    .featured-resource__title {
      color: $teal !important;
    }
  }
  &__thumbnail {
    margin: 0 em(15) 0 0;
    flex-shrink: 0;
    @media (max-width: 1199px) {
      width: 82px;
    }
    @media (min-width: 1200px) {
      width: 100px;
    }
  }
  &__image {
    width: 100%;
    height: auto;
  }
  &__content {
    text-align: left;
  }
  &__title {
    @extend %partial-underline;
    display: block;
    position: relative;
    transition: .25s color ease-in-out;
    margin-bottom: em(9, 22);
    padding-bottom: em(13.5, 22);
    line-height: (24 / 22);
    color: $gray-dark;
    font-family: $proxima;
    font-size: em(22);
    font-weight: 600;
    &:after {
      max-width: 45px;
    }
  }
  &__description {
    p {
      line-height: 1;
      font-size: em(16);
      @media (min-width: 992px) and (max-width: 1200px) {
        font-size: 14px;
      }
    }
  }
}

.featured-resources-section {
  @extend %constrain;
  padding-top: em(20);
  max-width: 1390px;
  > span {
    display: flex;
    @media (max-width: 767px) {
      flex-direction: column;
    }
    > div {
      @media (max-width: 767px) {
        width: 100%;
      }
      @media (min-width: 768px) and (max-width: 991px) {
        width: 50%;
      }
      @media (min-width: 992px) {
        width: 33.333%;
      }
      @media (max-width: 991px) {
        &:nth-child(n+3) {
          display: none;
        }
      }
    }
  }
}
