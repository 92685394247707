.cta-bar {
  position: relative;
  background-color: $gray-light;
  overflow: hidden;
  @media (max-width: 767px) {
    padding: 40px 19px 98px !important;
  }
  @media (min-width: 768px) and (max-width: 991px) {
    padding-right: em(44);
    padding-left: em(44);
  }
  @media (min-width: 992px) {
    padding-right: em(119);
    padding-left: em(119);
  }
  &__underlay {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    width: 100%;
    height: 100%;
  }
  &__content {
    position: relative;
    width: 100%;
    max-width: em(565);
    text-align: left;
    h2 {
      @extend %h1;
      margin-bottom: em(44, 64);
      line-height: (65 / 64);
      font-size: em(64) !important;
    }
    .cta-btn {
      margin-bottom: 42px;
    }
    p {
      letter-spacing: em(.5, 18);
      color: white;
      a {
        transition: .25s border-bottom ease-in-out;
        margin-left: em(9, 18);
        border-bottom: 1px solid white;
        color: white;
        &:hover {
          border-bottom: 1px solid transparent;
          text-decoration: none;
        }
      }
    }
  }
  &--small {
    padding-top: em(50);
    padding-bottom: em(50);
  }
  &--medium {
    padding-top: em(101);
    padding-bottom: em(101);
  }
  &--large {
    padding-top: em(150);
    padding-bottom: em(150);
  }
}
