.billboard {
  position: relative;
  background-color: black;
  overflow: hidden;
  &--small {
    height: em(499);
  }
  &--medium {
    height: em(515);
  }
  &--large {
    height: em(625);
  }
  &--full {
    height: 100vh;
  }
  &__underlay {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    width: 100%;
    height: 100%;
  }
  &__content {
    position: relative;
    text-align: left;
    * {
      color: white;
    }
    h1 {
      max-width: 795px;
    }
    .actions {
      margin-top: em(42);
      @media (max-width: 767px) {
        display: flex;
        flex-wrap: wrap;
        width: 250px;
        a {
          &:not(:last-child) {
            margin-bottom: em(25);
          }
        }
      }
      @media (min-width: 768px) {
        a {
          margin-right: em(50);
        }
      }
    }
    &-wrapper {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      display: flex;
      &--sides-padding {
        &-less {
          padding-left: em(48);
          padding-right: em(48);
        }
        &-more {
          padding-left: em(65);
          padding-right: em(65);
        }
      }
      &--top-bottom-padding {
        &-less {
          padding-top: em(50);
          padding-bottom: em(50);
        }
        &-more {
          padding-top: em(90);
          padding-bottom: em(90);
        }
      }
      &--position {
        &-top {
          align-items: flex-start;
        }
        &-center {
          align-items: center;
        }
        &-bottom {
          align-items: flex-end;
        }
      }
    }
  }
  &__menu {
    li > a {
      color: white;
      font-family: $proxima;
      font-size: em(18);
      font-weight: 400;
      line-height: (22 / 18);
      opacity: 1;
      transition: .25s opacity ease-in-out;
      &:hover {
        text-decoration: none;
        opacity: .7;
      }
    }
    &--breadcrumb,
    &--top-level {
      position: absolute;
      width: 100%;
      top: em(16);
      left: 0;
      right: 0;
      z-index: 1;
      @media (max-width: 767px) {
        padding-left: 7px;
        padding-right: 7px;
      }
      @media (min-width: 768px) {
        padding-left: em(50);
        padding-right: em(50);
      }
      ul {
        list-style-type: none;
        padding: 0;
        margin: 0;
      }
    }
    &--breadcrumb {
      > div > ul {
        li {
          padding: 0;
          margin: 0;
          a {
            font-weight: 400 !important;
          }
          &:not(:last-child) {
            &:after {
              content: '/';
              color: white;
              font-family: $proxima;
              font-size: em(18);
              font-weight: 400;
              line-height: (22 / 18);
              margin-left: em(14, 18);
              margin-right: em(14, 18);
            }
          }
          .hs-breadcrumb-menu-divider {
            display: none;
          }
          .hs-breadcrumb-label {
            color: white;
            font-family: $proxima;
            font-size: em(18);
            font-weight: 600;
            line-height: (22 / 18);
          }
        }
      }
    }
    &--top-level {
      > div > ul {
        .hs-menu-depth-1 {
          &:not(.active-branch) {
            display: none;
          }
          &.active-branch {
            @media (max-width: 767px) {
              display: inline;
              float: left;
              text-align: left;
              li {
                margin-top: em(5);
              }
            }
            @media (min-width: 768px) {
              display: flex;
            }
            > a {
              margin-right: em(45, 18);
              font-weight: 600;
              @media (max-width: 767px) {
                font-size: 12px;
              }
            }
            ul {
              @media (max-width: 767px) {
                display: inline;
                > li {
                  display: inline;
                }
              }
              @media (min-width: 768px) {
                display: flex;
              }
              li > a {
                margin-right: em(24, 18);
                font-weight: 400;
                display: inline-block;
                @media (max-width: 767px) {
                  font-size: 12px;
                }
              }
            }
          }
        }
      }
    }
  }
  &__play {
    position: absolute;
    width: em(73);
    height: em(73);
    top: 50%;
    transform: translateY(-50%);
    left: 0;
    right: 0;
    margin: auto;
    opacity: 1;
    transition: .25s opacity ease-in-out;
    cursor: pointer;
    &:hover {
      opacity: .8;
    }
    &-icon {
      width: em(73);
      height: em(73);
    }
  }
}
