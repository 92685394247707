.series-product-block {
  margin: 23px 0;
  @media (max-width: 991px) {
    padding: 0 em(30);
  }
  @media (min-width: 992px) {
    padding: 0 em(70);
  }
  &__inner {
    display: flex;
    align-items: center;
    @media (max-width: 767px) {
      flex-direction: column;
    }
    @media (min-width: 768px) {
      justify-content: space-between;
    }
    &--flip {
      @media (min-width: 768px) {
        flex-direction: row-reverse;
      }
      .series-product-block__photo {
        @extend %corners;
        @extend %corners--top-right;
      }
    }
    &:not(&--flip) {
      .series-product-block__photo {
        @extend %corners;
        @extend %corners--top-left;
      }
    }
  }
  &__content,
  &__photo {
    @media (min-width: 768px) {
      width: calc(50% - 37.5px);
    }
  }
  &__content {
    padding: em(43);
    text-align: left;
    h2 {
      @extend %partial-underline;
      color: $gray-dark;
      font-family: $proxima;
      font-size: em(35);
      font-weight: 700;
      line-height: (42 / 35);
      margin-bottom: em(22, 35);
      padding-bottom: em(15, 35);
      text-transform: none;
      letter-spacing: 0;
      &:after {
        max-width: 105px;
        height: 2px;
      }
    }
  }
  &__photo {
    position: relative;
  }
  &__image {
    width: 100%;
    height: auto;
  }
}
