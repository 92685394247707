.post {
  .inner {
    display: block;
    padding: em(58) em(49);
    position: relative;
    z-index: 1;
    text-align: left;
    &:before {
      content: '';
      display: block;
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 0 em(38.5) em(53);
      border-color: transparent transparent white;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      margin: auto;
      transform: translateY(-40%);
      z-index: -1;
      transition: .25s transform ease-in-out;
    }
  }
  &__link {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    color: inherit;
    &:hover {
      text-decoration: none;
      .inner {
        &:before {
          transform: translateY(-70%);
        }
      }
      .post {
        &__title {
          color: $teal;
        }
      }
    }
  }
  &__title {
    display: block;
    color: $gray-dark;
    font-family: $bebas;
    font-size: em(32);
    font-weight: 400;
    letter-spacing: em(1, 32);
    line-height: (37 / 32);
    margin-bottom: em(19, 32);
    text-transform: uppercase;
    transition: .25s color ease-in-out;
  }
  &__date,
  &__author {
    display: block;
  }
  &__date {
    color: $gray;
    font-family: $proxima;
    font-size: em(18);
    font-weight: 400;
    line-height: (22 / 18);
    margin-bottom: em(3, 18);
  }
  &__content {
    display: block;
  }
  &__topics {
    list-style-type: none;
    padding: 0;
    margin: 0 0 em(43);
    color: $gray;
    font-family: $proxima;
    font-size: em(15);
    font-weight: 700;
    line-height: (18 / 15);
    text-transform: uppercase;
    li {
      display: inline;
      &:not(:last-child) {
        &:after {
          content: '/';
          margin: 0 em(3, 15);
        }
      }
    }
  }
  &__read-more {
    display: block;
    color: $teal;
    font-family: $proxima;
    font-size: em(14);
    font-weight: 700;
    letter-spacing: em(.58, 14);
    line-height: (17 / 14);
  }
  &__image {
    display: block;
    position: relative;
    width: 100%;
    height: 0;
    padding-bottom: 100%;
    img {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
    }
  }
  &__floating-share {
    transform: translateZ(0%);
    @media (max-width: 767px) {
      display: none;
    }
    &:not(&--travel) {
      position: absolute;
      right: 0;
      top: 30px;
    }
    &--travel {
      position: fixed;
    }
    &--bottom {
      position: absolute;
      top: auto !important;
      right: 0 !important;
      bottom: 0 !important;
    }
  }
  &:not(.card) {
    .post__image {
      max-height: none;
    }
  }
  &.card {
    @media (min-width: 768px) {
      .post__image {
        height: em(352);
        overflow: hidden;
      }
    }
  }
}
