.cta-btn,
%cta-btn {
  display: inline-block;
  position: relative;
  transition: .5s all ease-in-out;
  border: 0;
  text-align: center;
  font-family: $proxima;
  font-weight: 700;
  &:hover {
    text-decoration: none;
  }
  span {
    font-size: inherit;
  }
  &--small {
    padding: em(10.5, 16) em(28.5, 16);
    font-size: em(16);
  }
  &--medium {
    padding: em(13, 15) em(24, 15);
    text-transform: uppercase;
    font-size: em(15);
  }
  &--large {
    padding: em(14, 18) em(26, 18);
    text-transform: uppercase;
    font-size: em(18);
  }
  &--xl {
    padding: em(24, 22) em(58, 22);
    font-size: em(22);
  }
  &--solid {
    border: 2px solid $orange;
    border-radius: 2px;
    background-color: $orange;
    color: white;
    &:hover {
      border: 2px solid white;
      background-color: white;
      color: $orange;
    }
  }
  &--solid-with-border {
    border: 2px solid $orange;
    border-radius: 2px;
    background-color: $orange;
    color: white;
    &:hover {
      background-color: white;
      color: $orange;
    }
  }
  &--tinted {
    border: 2px solid $orange;
    border-radius: 2px;
    background-color: rgba(0, 0, 0, .25);
    color: white;
    &:hover {
      background-color: $orange;
    }
  }
}
