.product-page {
  &__inner {
    @extend %constrain;
    @extend %constrain--full;
    > div > div {
      display: flex;
      @media (max-width: 991px) {
        padding: em(75) em(20);
      }
      @media (max-width: 991px) {
        flex-direction: column;
      }
      @media (min-width: 992px) {
        padding: em(75) em(65);
      }
    }
  }
  &__content,
  &__sidebar {
    text-align: left;
  }
  &__content {
    @media (max-width: 991px) {
      margin-bottom: em(30);
    }
    @media (min-width: 992px) {
      width: 66.666%;
      padding-right: em(69);
    }
    @media (min-width: 1200px) {
      padding-right: em(138);
    }
    h2 {
      @extend %partial-underline;
      color: $gray-dark;
      font-family: $proxima;
      font-size: em(35) !important;
      font-weight: 700 !important;
      line-height: (42 / 35) !important;
      text-transform: none !important;
      margin-top: em(55, 35);
      margin-bottom: em(27, 35);
      padding-bottom: em(10, 35) !important;
      letter-spacing: 0 !important;
      &:after {
        height: 2px;
        max-width: 150px;
      }
    }
    p {
      font-size: em(22);
      margin-bottom: em(22, 22);
    }
    .colors p {
      font-size: em(18, 16);
    }
  }
  &__sidebar {
    @media (min-width: 992px) {
      width: 33.333%;
    }
  }
}

.product-sidebar-cta-area {
  margin-bottom: em(38);
  @media (max-width: 991px) {
    text-align: center;
  }
  .cta_button {
    @extend %cta-btn;
    @extend %cta-btn--solid-with-border;
    @extend %cta-btn--xl;
    @media (min-width: 992px) {
      display: block;
    }
  }
}

.product-details,
.trim-and-accessories,
.product-documentation {
  margin-bottom: em(50);
  p {
    margin: em(23, 18) 0;
  }
}

.product-sidebar-header {
  display: flex;
  padding-bottom: em(16);
  border-bottom: 2px solid $orange;
  align-items: center;
  &__expand-all {
    color: $teal;
    font-family: $proxima;
    font-size: em(15);
    font-weight: 400;
    letter-spacing: em(1, 15);
    line-height: 1;
    border: 0;
    padding: 0;
    background: transparent;
    transition: .25s color ease-in-out;
    &:hover {
      color: darken($teal, 10%);
    }
    &:focus {
      outline: 0;
    }
  }
  h5 {
    flex-grow: 1;
    margin: 0;
  }
}
