.find-your-color {
  @media (max-width: 767px) {
    padding-left: em(30);
    padding-right: em(30);
    font-size: 10px;
  }
  @media (min-width: 768px) {
    padding-left: em(68);
    padding-right: em(68);
  }
  &__header {
    display: flex;
    flex-direction: column;
    padding-top: em(54);
    padding-bottom: em(48);
    text-align: left;
  }
  &__title {
    margin-top: 0;
    margin-bottom: em(3, 64);
  }
  &__subtitle {
    margin-top: 0;
    margin-bottom: 0;
  }
  &__types {
    display: flex;
    align-items: center;
    &-label {
      color: $gray-dark;
      font-family: $proxima;
      font-size: em(20);
      font-weight: 700;
      letter-spacing: em(1, 20);
      line-height: (24 / 18);
    }
    &-list {
      list-style-type: none;
      margin: 0 0 0 em(7);
      padding: 0;
      display: flex;
    }
    &-selector {
      color: $gray-dark;
      font-family: $proxima;
      font-size: em(18);
      font-weight: 400;
      line-height: (22 / 18);
      transition: .25s all ease-in-out;
      padding: 0;
      border: 0;
      margin: 0 !important;
      background: transparent;
      border-bottom: 1px solid transparent;
      max-width: 218px;
      &:hover {
        color: $orange;
      }
      &:focus {
        outline: 0;
      }
      &--active {
        color: $orange;
        border-bottom: 1px solid $orange;
      }
      &-current-value {
        text-overflow: ellipsis;
      }
    }
    &-item {
      margin: 0 em(20) !important;
    }
  }
  &__controls {
    display: flex;
    width: 100%;
    margin-top: em(28);
    margin-bottom: em(35);
    align-items: center;
    justify-content: space-between;
    @media (min-width: 768px) and (max-width: 991px) {
      font-size: 12px;
    }
    @media (min-width: 992px) and (max-width: 1199px) {
      font-size: 13px;
    }
    @media (min-width: 1200px) and (max-width: 1399px) {
      font-size: 14px;
    }
  }
  &__selectors-group {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
  }
  &__selector {
    position: relative;
    text-align: left;
    margin-right: em(35);
    &:not(&--active) {
      .find-your-color__selector-content {
        display: none;
      }
    }
    &--active {
      .find-your-color__selector-content {
        display: block;
      }
      .find-your-color__selector-current {
        background-color: $gray-lighter;
      }
    }
    &--disabled {
      display: none;
    }
    &-current {
      display: block;
      font-size: em(18);
      padding: em(10, 18) em(42, 18) em(10, 18) em(18, 18);
      cursor: pointer;
      position: relative;
      &:after {
        content: '';
        position: absolute;
        width: em(12, 18);
        height: em(7, 18);
        background-image: url('//cdn2.hubspot.net/hubfs/1608284/assets/images/find-your-color-arrow.svg');
        background-size: contain;
        right: em(18, 18);
        top: 50%;
        transform: translateY(-50%);
      }
      &-value {

      }
    }
    &-content {
      position: absolute;
      background-color: white;
      border: 1px solid #979797;
      left: 0;
      top: 100%;
      width: 225px;
      max-height: 464px;
      overflow-y: scroll;
      list-style-type: none;
      padding: 0;
      margin: 0;
      text-align: left;
      z-index: 3;
    }
    &-item {
      display: flex;
      align-items: center;
      margin-bottom: 0;
      background-color: white;
      transition: .25s background-color ease-in-out;
      &:hover {
        background-color: $gray-lightest;
      }
    }
    &-color {
      width: em(50);
      height: em(50);
      margin: 0 em(19) 0 0;
      flex-shrink: 0;
    }
  }
  &__selector-current,
  &__reset {
    background-color: $gray-lightest;
    transition: .25s background-color ease-in-out;
    &:hover {
      background-color: $gray-lighter;
    }
  }
  &__reset {
    color: $gray-dark;
    font-family: $proxima;
    font-size: em(18);
    font-weight: 400;
    line-height: (22 / 18);
    padding: em(10, 18) em(33, 18);
    border: 0;
    &:focus {
      outline: 0;
    }
  }
  &__view {
    background-size: 100% 100%;
    background-repeat: no-repeat;
    background-position: center center;
    z-index: 1;
    &--active {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
    }
    &--residential,
    &--commercial,
    &--agricultural {
      &:after {
        content: '';
        display: block;
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        background-size: 100% 100%;
        background-repeat: no-repeat;
        background-position: center center;
        z-index: 8;
      }
    }
    &--residential {
      background-image: url('//cdn2.hubspot.net/hubfs/1608284/assets/images/find-a-color/residential-desat.jpg');
      &:after {
        background-image: url('//cdn2.hubspot.net/hubfs/1608284/assets/images/find-a-color/residential-shadows.png');
        opacity: .15;
      }
    }
    &--commercial {
      background-image: url('//cdn2.hubspot.net/hubfs/1608284/assets/images/find-a-color/commercial-desat.jpg');
      &:after {
        background-image: url('//cdn2.hubspot.net/hubfs/1608284/assets/images/find-a-color/commercial-shadows.png');
        opacity: .4;
      }
    }
    &--agricultural {
      background-image: url('//cdn2.hubspot.net/hubfs/1608284/assets/images/find-a-color/agricultural-desat.jpg');
      &:after {
        background-image: url('//cdn2.hubspot.net/hubfs/1608284/assets/images/find-a-color/agricultural-shadows.png');
        opacity: .25;
      }
    }
    &:not(&--active) {
      display: none;
    }
    &-container {
      position: relative;
      width: 100%;
      height: 0;
      padding-bottom: 47.5%;
      background-color: $gray-light;
      opacity: 0;
      transition: .25s opacity ease-in-out;
      &--revealed {
        opacity: 1;
      }
    }
  }
  &__layer {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 2;
    &--roof,
    &--siding,
    &--trim,
    &--wainscot {
      &[data-is-bright="true"] {
        opacity: .9;
      }
      &[data-is-bright="false"] {
        opacity: .66;
      }
      [fill] {
        transition: .25s fill ease-in-out;
      }
    }
    &--roof {
      z-index: 5;
    }
    &--trim {
      z-index: 4;
    }
    &--siding {
      z-index: 3;
    }
    &--wainscot {
      z-index: 6;
    }
  }
  &__disclaimer {
    padding-top: em(7);
    text-align: left;
    &-text {
      color: $gray-dark;
      font-family: $proxima;
      font-size: em(15);
      font-weight: 400;
      line-height: (18 / 15);
      display: block;
      width: 100%;
      text-align: left;
    }
  }
}
