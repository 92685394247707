.metal-roofing-and-siding-menu {
  display: flex;
  padding: em(60) em(7) em(30);
  @media (max-width: 767px) {
    flex-direction: column;
  }
  &__source {
    display: none;
  }
  &__column {
    @media (max-width: 767px) {
      margin-bottom: em(45);
      padding-right: 21px;
      padding-left: 21px;
    }
    @media (min-width: 768px) {
      padding-right: em(21);
      padding-left: em(21);
      width: 33.333%;
    }
  }
  &__content {
    margin-bottom: em(30);
    text-align: left;
    h2 {
      margin-bottom: em(19, 35);
      text-transform: none;
      line-height: (42 / 35);
      letter-spacing: 0;
      color: $gray-dark;
      font-family: $proxima;
      font-size: em(35);
      font-weight: 700;
      @media (max-width: 991px) {
        font-size: em(26);
      }
    }
  }
  &__menu {
    margin: 0;
    padding: 0;
    text-align: left;
    list-style-type: none;
    > li {
      &:not(.hs-item-has-children) {
        > a {
          @extend %simple-menu-link;
        }
      }
      &.hs-item-has-children {
        overflow: hidden;
        > a {
          color: $gray-dark;
          &:hover {
            text-decoration: none;
          }
        }
        &.open {
          ul {
            opacity: 1;
            height: auto;
            a {
              @extend %simple-menu-link;
            }
          }
        }
        &:not(.open) {
          > ul {
            opacity: 0;
            height: 0;
          }
        }
        ul {
          transition: .5s opacity ease-in-out;
          margin: 0;
          padding: 0 0 0 em(12);
          list-style-type: none;
          a {
            transition: 0s border-bottom ease-in-out !important;
          }
        }
      }
    }
  }
  &__expander {
    @extend %simple-menu-expander;
  }
}
