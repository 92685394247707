.raised-up-header {
  margin-top: em(-60);
  text-align: left;
  @media (max-width: 991px) {
    padding-left: em(30);
    padding-right: em(30);
  }
  @media (min-width: 992px) {
    padding-left: em(89);
    padding-right: em(89);
  }
  &__inner {
    position: relative;
    display: block;
    width: 100%;
    background-color: $orange;
    padding: em(35) em(27) em(70);
    z-index: 1;
    @media (min-width: 768px) {
      max-width: 586px;
    }
    p {
      color: white;
      font-family: $proxima;
      font-size: em(22);
      font-weight: 400;
      line-height: (31 / 22);
      margin: 0;
    }
  }
}
